export const polygonPools = [
  {
    id: 'polygon-bifi-maxi',
    logo: 'single-assets/BIFI.png',
    name: 'BIFI Maxi',
    token: 'BIFI',
    tokenDescription: 'Beefy.Finance',
    tokenAddress: '0xFbdd194376de19a88118e84E279b977f165d01b8',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPolygonBIFI',
    earnedTokenAddress: '0xfEcf784F48125ccb7d8855cdda7C5ED6b5024Cb3',
    earnContractAddress: '0xfEcf784F48125ccb7d8855cdda7C5ED6b5024Cb3',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'BIFI',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Beefy.Finance',
    assets: ['BIFI'],
    callFee: 0.05,
    withdrawalFee: '0.05%',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0xfbdd194376de19a88118e84e279b977f165d01b8',
  },
  {
    id: 'jetswap-poly-usdc-usdt',
    name: 'USDC-USDT LP',
    token: 'USDC-USDT LP',
    tokenDescription: 'JetSwap',
    tokenAddress: '0x20BF018FDDBa3b352f3d913FE1c81b846fE0F490',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJetSwapUSDC-USDT',
    earnedTokenAddress: '0x93c9f29CF2496e73f3d8b07055e2359267207147',
    earnContractAddress: '0x93c9f29CF2496e73f3d8b07055e2359267207147',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jetswap-poly-usdc-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'JetSwap',
    assets: ['USDC', 'USDT'],
    callFee: 0.5,
    withdrawalFee: '0.01%',
    addLiquidityUrl:
      'https://polygon-exchange.jetswap.finance/#/add/0xc2132D05D31c914a87C6611C10748AEb04B58e8F/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
  },
  {
    id: 'mai-usdc-mimatic',
    name: 'MAI-USDC LP',
    token: 'MAI-USDC QLP',
    tokenDescription: 'QuickSwap (Mai)',
    tokenAddress: '0x160532D2536175d65C03B97b0630A9802c274daD',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooMaiUSDC-miMATIC',
    earnedTokenAddress: '0xebe0c8d842AA5A57D7BEf8e524dEabA676F91cD1',
    earnContractAddress: '0xebe0c8d842AA5A57D7BEf8e524dEabA676F91cD1',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'mai-usdc-mimatic',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Mai',
    assets: ['MAI', 'USDC'],
    callFee: 0.5,
    depositFee: '0.5%',
    withdrawalFee: '0.0%',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x2791bca1f2de4661ed88a30c99a7a9449aa84174/0xa3Fa99A148fA48D14Ed51d610c367C61876997F1',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x2791bca1f2de4661ed88a30c99a7a9449aa84174&outputCurrency=0xa3Fa99A148fA48D14Ed51d610c367C61876997F1',
  },
  {
    id: 'polypup-usdc-bone',
    name: 'BONE-USDC QLP',
    token: 'BONE-USDC QLP',
    tokenDescription: 'Polypup',
    tokenAddress: '0x2CC05c660f35E8692CA99dB95922CB744d44ef20',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPolypupUSDC-BONE',
    earnedTokenAddress: '0x8Ce906F6f383c31b09B1D2A5f2c9f480b87ceb58',
    earnContractAddress: '0x8Ce906F6f383c31b09B1D2A5f2c9f480b87ceb58',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'polypup-usdc-bone',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Polypup',
    assets: ['USDC', 'BONE'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/0x6bb45cEAC714c52342Ef73ec663479da35934bf7',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174&outputCurrency=0x6bb45cEAC714c52342Ef73ec663479da35934bf7',
  },
  {
    id: 'quick-nexo-eth',
    name: 'NEXO-ETH LP',
    token: 'NEXO-ETH LP',
    tokenDescription: 'Quickswap',
    tokenAddress: '0x10062ec62C0bE26cC9e2f50a1CF784a89ded075F',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickNEXO-ETH',
    earnedTokenAddress: '0xba1548b3f673950094dc00eDc1eB71683f371696',
    earnContractAddress: '0xba1548b3f673950094dc00eDc1eB71683f371696',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-nexo-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['NEXO', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619/0x41b3966B4FF7b427969ddf5da3627d6AEAE9a48E',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0x41b3966B4FF7b427969ddf5da3627d6AEAE9a48E',
  },
  {
    id: 'quick-nexo-quick',
    name: 'NEXO-QUICK LP',
    token: 'NEXO-QUICK LP',
    tokenDescription: 'Quickswap',
    tokenAddress: '0xaCA37A3282078dbf41a6D0C6314C53D7F9CeD6EC',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickNEXO-QUICK',
    earnedTokenAddress: '0x832dEe19e5EFeA9251d967f96c324B154780384B',
    earnContractAddress: '0x832dEe19e5EFeA9251d967f96c324B154780384B',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-nexo-quick',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['NEXO', 'QUICK'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619/0x41b3966B4FF7b427969ddf5da3627d6AEAE9a48E',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0x41b3966B4FF7b427969ddf5da3627d6AEAE9a48E',
  },
  {
    id: 'telxchange-quick-quick-tel',
    name: 'TEL-QUICK QLP',
    token: 'TEL-QUICK QLP',
    tokenDescription: 'Telxchange',
    tokenAddress: '0xE88e24F49338f974B528AcE10350Ac4576c5c8A1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooTelxchangeQuickQUICK-TEL',
    earnedTokenAddress: '0xda3E3ee01Ee7284e3D0d900Ca3fb3D09484E1ce0',
    earnContractAddress: '0xda3E3ee01Ee7284e3D0d900Ca3fb3D09484E1ce0',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'telxchange-quick-quick-tel',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Telxchange',
    assets: ['QUICK', 'TEL'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x831753DD7087CaC61aB5644b308642cc1c33Dc13/0xdF7837DE1F2Fa4631D716CF2502f8b230F1dcc32',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x831753DD7087CaC61aB5644b308642cc1c33Dc13&outputCurrency=0xdF7837DE1F2Fa4631D716CF2502f8b230F1dcc32',
  },
  {
    id: 'telxchange-quick-wmatic-tel',
    name: 'TEL-MATIC QLP',
    token: 'TEL-MATIC QLP',
    tokenDescription: 'Telxchange',
    tokenAddress: '0x9b5c71936670e9f1F36e63F03384De7e06E60d2a',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooTelxchangeQuickWMATIC-TEL',
    earnedTokenAddress: '0x2AaB02eDE64d4A686EA1d1A70B688E46cce50fFd',
    earnContractAddress: '0x2AaB02eDE64d4A686EA1d1A70B688E46cce50fFd',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'telxchange-quick-wmatic-tel',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Telxchange',
    assets: ['MATIC', 'TEL'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270/0xdF7837DE1F2Fa4631D716CF2502f8b230F1dcc32',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270&outputCurrency=0xdF7837DE1F2Fa4631D716CF2502f8b230F1dcc32',
  },
  {
    id: 'telxchange-quick-eth-tel',
    name: 'TEL-ETH QLP',
    token: 'TEL-ETH QLP',
    tokenDescription: 'Telxchange',
    tokenAddress: '0xFc2fC983a411C4B1E238f7Eb949308CF0218C750',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooTelxchangeQuickETH-TEL',
    earnedTokenAddress: '0xDC52e53f9E8b2cf42b557A6b3cE36e79De39ACd6',
    earnContractAddress: '0xDC52e53f9E8b2cf42b557A6b3cE36e79De39ACd6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'telxchange-quick-eth-tel',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Telxchange',
    assets: ['ETH', 'TEL'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0xdF7837DE1F2Fa4631D716CF2502f8b230F1dcc32',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0xdF7837DE1F2Fa4631D716CF2502f8b230F1dcc32',
  },
  {
    id: 'telxchange-quick-aave-tel',
    name: 'TEL-AAVE QLP',
    token: 'TEL-AAVE QLP',
    tokenDescription: 'Telxchange',
    tokenAddress: '0x4917bC6b8E705Ad462ef525937E7eB7C6c87C356',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooTelxchangeQuickAAVE-TEL',
    earnedTokenAddress: '0xBa58D7131Ca50DdFB458f37ea0De6fdcebFe836B',
    earnContractAddress: '0xBa58D7131Ca50DdFB458f37ea0De6fdcebFe836B',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'telxchange-quick-aave-tel',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Telxchange',
    assets: ['AAVE', 'TEL'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0xD6DF932A45C0f255f85145f286eA0b292B21C90B/0xdF7837DE1F2Fa4631D716CF2502f8b230F1dcc32',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0xD6DF932A45C0f255f85145f286eA0b292B21C90B&outputCurrency=0xdF7837DE1F2Fa4631D716CF2502f8b230F1dcc32',
  },
  {
    id: 'telxchange-quick-wbtc-tel',
    name: 'TEL-BTC QLP',
    token: 'TEL-BTC QLP',
    tokenDescription: 'Telxchange',
    tokenAddress: '0xaDdc9C73f3CBaD4E647eAFf691715898825Ac20c',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooTelxchangeQuickWBTC-TEL',
    earnedTokenAddress: '0xb74792F6a2cF81E3f704930621cb4832ffFcC840',
    earnContractAddress: '0xb74792F6a2cF81E3f704930621cb4832ffFcC840',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'telxchange-quick-wbtc-tel',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Telxchange',
    assets: ['WBTC', 'TEL'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6/0xdF7837DE1F2Fa4631D716CF2502f8b230F1dcc32',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6&outputCurrency=0xdF7837DE1F2Fa4631D716CF2502f8b230F1dcc32',
  },
  {
    id: 'curve-poly-atricrypto',
    logo: 'uncategorized/ATRICRYPTO.png',
    name: 'aTriCrypto',
    token: 'crvUSDBTCETH',
    tokenDescription: 'Curve',
    tokenAddress: '0x8096ac61db23291252574D49f036f0f9ed8ab390',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCurveATriCrypto',
    earnedTokenAddress: '0x3dab1aCB811dc4C8b2FdC77812552f4d4Efd0A8c',
    earnContractAddress: '0x3dab1aCB811dc4C8b2FdC77812552f4d4Efd0A8c',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'curve-poly-atricrypto',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Curve',
    assets: ['DAI', 'USDC', 'USDT', 'WBTC', 'ETH'],
    callFee: 0.5,
    withdrawalFee: '0.01%',
    addLiquidityUrl: 'https://polygon.curve.fi/atricrypto/deposit',
  },
  {
    id: 'dfyn-ice-dfyn',
    name: 'ICE-DFYN LP',
    token: 'ICE-DFYN LP',
    tokenDescription: 'DFyn',
    tokenAddress: '0x9bb608dc0F9308B9beCA2F7c80865454d02E74cA',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooDfynICE-DFYN',
    earnedTokenAddress: '0xB198A916123394f2d9c31D4645468566e87080d5',
    earnContractAddress: '0xB198A916123394f2d9c31D4645468566e87080d5',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'dfyn-ice-dfyn',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'DFyn',
    assets: ['ICE', 'DFYN'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.dfyn.network/#/add/0x4A81f8796e0c6Ad4877A51C86693B0dE8093F2ef/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    buyTokenUrl:
      'https://exchange.dfyn.network/#/swap?outputCurrency=0x4A81f8796e0c6Ad4877A51C86693B0dE8093F2ef',
  },
  {
    id: 'ironswap-ice-eth',
    name: 'ICE-ETH LP',
    token: 'ICE-ETH LP',
    tokenDescription: 'IronSwap (Firebird)',
    tokenAddress: '0xf1EE78544a1118F2efb87f7EaCd9f1E6e80e1ea5',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooIronSwapICE-ETH',
    earnedTokenAddress: '0x90D0F2050e62a6d4AF088dD09d802D9A18253c40',
    earnContractAddress: '0x90D0F2050e62a6d4AF088dD09d802D9A18253c40',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'ironswap-ice-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'IronFinance',
    assets: ['ICE', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl: 'https://app.firebird.finance/add/0xf1ee78544a1118f2efb87f7eacd9f1e6e80e1ea5',
    buyTokenUrl:
      'https://app.firebird.finance/swap?outputCurrency=0x4A81f8796e0c6Ad4877A51C86693B0dE8093F2ef',
  },
  {
    id: 'ironswap-ice-usdc',
    name: 'ICE-USDC LP',
    token: 'ICE-USDC LP',
    tokenDescription: 'IronSwap (DFYN)',
    tokenAddress: '0x34832D9AC4127a232C1919d840f7aaE0fcb7315B',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooIronSwapICE-USDC',
    earnedTokenAddress: '0x0564889d4Ff0dA0371Ec8a898AC39ABC99065BfA',
    earnContractAddress: '0x0564889d4Ff0dA0371Ec8a898AC39ABC99065BfA',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'ironswap-ice-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'IronFinance',
    assets: ['ICE', 'USDC'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.dfyn.network/#/add/0x4A81f8796e0c6Ad4877A51C86693B0dE8093F2ef/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    buyTokenUrl:
      'https://exchange.dfyn.network/#/swap?outputCurrency=0x4A81f8796e0c6Ad4877A51C86693B0dE8093F2ef',
  },
  {
    id: 'ironswap-3usd',
    logo: 'uncategorized/IRON-3USD.png',
    name: 'USDC/USDT/DAI',
    token: 'IS3USD',
    tokenDescription: 'IronSwap',
    tokenAddress: '0xb4d09ff3dA7f9e9A2BA029cb0A81A989fd7B8f17',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooIronSwap3USD',
    earnedTokenAddress: '0x0C4705EBd2BF69660b72f9E9B684714c9e341942',
    earnContractAddress: '0x0C4705EBd2BF69660b72f9E9B684714c9e341942',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'ironswap-3usd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'IronFinance',
    assets: ['USDC', 'USDT', 'DAI'],
    callFee: 0.5,
    withdrawalFee: '0.01%',
    addLiquidityUrl: 'https://app.iron.finance/swap/pools/is3usd/deposit',
  },
  {
    id: 'wexpoly-wbtc-wex',
    logo: 'polygon/WEXPOLY-BTC.png',
    name: 'WBTC-WEXpoly LP',
    token: 'WBTC-WEXpoly WLP',
    tokenDescription: 'WaultFinance',
    tokenAddress: '0xaE183DB956FAf760Aa29bFcfDa4BDDdB02fbdd0E',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooWexPolyWBTC-WEX ',
    earnedTokenAddress: '0xA1B2Ef1b6D5823D832DDa2AE2B489793832Fd082',
    earnContractAddress: '0xA1B2Ef1b6D5823D832DDa2AE2B489793832Fd082',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wexpoly-wbtc-wex',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'WaultFinance',
    assets: ['WBTC', 'WEXPoly'],
    callFee: 0.05,
    addLiquidityUrl:
      'https://swap.wault.finance/polygon/#/add/0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6/0x4c4BF319237D98a30A929A96112EfFa8DA3510EB',
    buyTokenUrl:
      'https://swap.wault.finance/polygon/#/swap?inputCurrency=0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6&outputCurrency=0x4c4BF319237D98a30A929A96112EfFa8DA3510EB',
  },
  {
    id: 'dfyn-bifi-dfyn',
    name: 'BIFI-DFYN LP',
    token: 'BIFI-DFYN DLP',
    tokenDescription: 'DFyn',
    tokenAddress: '0x759738096589b9C39dA78A603565635a3Cee6013',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooDfynBIFI-DFYN',
    earnedTokenAddress: '0x74b00bbb693de4dC07d8aB79Ac935b323e2A136a',
    earnContractAddress: '0x74b00bbb693de4dC07d8aB79Ac935b323e2A136a',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'dfyn-bifi-dfyn',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'DFyn',
    assets: ['BIFI', 'DFYN'],
    callFee: 0.05,
    addLiquidityUrl:
      'https://exchange.dfyn.network/#/add/0xfbdd194376de19a88118e84e279b977f165d01b8/0xC168E40227E4ebD8C1caE80F7a55a4F0e6D66C97',
    buyTokenUrl:
      'https://exchange.dfyn.network/#/swap?inputCurrency=0xfbdd194376de19a88118e84e279b977f165d01b8&outputCurrency=0xC168E40227E4ebD8C1caE80F7a55a4F0e6D66C97',
  },
  {
    id: 'curve-poly-ren',
    logo: 'single-assets/renBTC.png',
    name: 'WBTC/renBTC',
    token: 'btcCRV',
    tokenDescription: 'Curve',
    tokenAddress: '0xf8a57c1d3b9629b77b6726a042ca48990A84Fb49',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCurveRen',
    earnedTokenAddress: '0x8c9d3Bc4425773BD2F00C4a2aC105c5Ad73c8141',
    earnContractAddress: '0x8c9d3Bc4425773BD2F00C4a2aC105c5Ad73c8141',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'curve-poly-ren',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Curve',
    assets: ['WBTC', 'renBTC'],
    callFee: 0.5,
    withdrawalFee: '0.01%',
    addLiquidityUrl: 'https://polygon.curve.fi/ren/deposit',
  },
  {
    id: 'jetswap-poly-pwings',
    logo: 'single-assets/pWINGS.png',
    name: 'pWINGS',
    token: 'pWINGS',
    tokenDescription: 'JetSwap',
    tokenAddress: '0x845E76A8691423fbc4ECb8Dd77556Cb61c09eE25',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJetSwapPWINGS',
    earnedTokenAddress: '0xa55e8326a9AC7B0bFcA5E6Fc9Cf1141E2dc11f67',
    earnContractAddress: '0xa55e8326a9AC7B0bFcA5E6Fc9Cf1141E2dc11f67',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'pWINGS',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'JetSwap',
    assets: ['pWINGS'],
    callFee: 0.5,
    buyTokenUrl:
      'https://polygon-exchange.jetswap.finance/#/swap?outputCurrency=0x845E76A8691423fbc4ECb8Dd77556Cb61c09eE25',
  },
  {
    id: 'jetswap-poly-pwings-usdc-eol',
    name: 'pWINGS-USDC LP',
    token: 'pWINGS-USDC LP',
    tokenDescription: 'JetSwap',
    tokenAddress: '0xaf623E96d38191038C48990Df298e07Fb77b56c3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJetSwapPWINGS-USDC',
    earnedTokenAddress: '0xaA66e6EA1cAa7B86a8119B1420F6EdeDE3bb58f1',
    earnContractAddress: '0xaA66e6EA1cAa7B86a8119B1420F6EdeDE3bb58f1',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jetswap-poly-pwings-usdc',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'JetSwap',
    assets: ['pWINGS', 'USDC'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://polygon-exchange.jetswap.finance/#/add/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/0x845E76A8691423fbc4ECb8Dd77556Cb61c09eE25',
    buyTokenUrl:
      'https://polygon-exchange.jetswap.finance/#/swap?outputCurrency=0x845E76A8691423fbc4ECb8Dd77556Cb61c09eE25',
  },
  {
    id: 'jetswap-poly-pwings-matic',
    name: 'pWINGS-MATIC LP',
    token: 'pWINGS-MATIC LP',
    tokenDescription: 'JetSwap',
    tokenAddress: '0xA0A6e9A5185d5737CF6F7920CB417EA2F07F03B3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJetSwapPWINGS-MATIC',
    earnedTokenAddress: '0x97fe99a9bc7db115EC89Bc2627eC7f0518409121',
    earnContractAddress: '0x97fe99a9bc7db115EC89Bc2627eC7f0518409121',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jetswap-poly-pwings-matic',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'JetSwap',
    assets: ['pWINGS', 'MATIC'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://polygon-exchange.jetswap.finance/#/add/MATIC/0x845E76A8691423fbc4ECb8Dd77556Cb61c09eE25',
    buyTokenUrl:
      'https://polygon-exchange.jetswap.finance/#/swap?outputCurrency=0x845E76A8691423fbc4ECb8Dd77556Cb61c09eE25',
  },
  {
    id: 'jetswap-poly-pwings-usdt-eol',
    name: 'pWINGS-USDT LP',
    token: 'pWINGS-USDT LP',
    tokenDescription: 'JetSwap',
    tokenAddress: '0xA39a7640790907D4865a74c1F9715715DBd00431',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJetSwapPWINGS-USDT',
    earnedTokenAddress: '0x5C6e97Ae01d2bE807c8D5d23B9dF63D3A5eF8E9B',
    earnContractAddress: '0x5C6e97Ae01d2bE807c8D5d23B9dF63D3A5eF8E9B',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jetswap-poly-pwings-usdt',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'JetSwap',
    assets: ['pWINGS', 'USDT'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://polygon-exchange.jetswap.finance/#/add/0xc2132D05D31c914a87C6611C10748AEb04B58e8F/0x845E76A8691423fbc4ECb8Dd77556Cb61c09eE25',
    buyTokenUrl:
      'https://polygon-exchange.jetswap.finance/#/swap?outputCurrency=0x845E76A8691423fbc4ECb8Dd77556Cb61c09eE25',
  },
  {
    id: 'mai-qi-mimatic',
    name: 'QI-MAI LP',
    token: 'QI-MAI QLP',
    tokenDescription: 'QuickSwap (Mai)',
    tokenAddress: '0x7AfcF11F3e2f01e71B7Cc6b8B5e707E42e6Ea397',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooMaiQI-miMATIC',
    earnedTokenAddress: '0xa2Dd60Fb8Bbf21e60E58AF97Cfc9dB9A34397848',
    earnContractAddress: '0xa2Dd60Fb8Bbf21e60E58AF97Cfc9dB9A34397848',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'mai-qi-mimatic',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Mai',
    assets: ['QI', 'MAI'],
    callFee: 0.5,
    depositFee: '0.5%',
    withdrawalFee: '0.0%',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x580A84C73811E1839F75d86d75d88cCa0c241fF4/0xa3Fa99A148fA48D14Ed51d610c367C61876997F1',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0xa3fa99a148fa48d14ed51d610c367c61876997f1&outputCurrency=0x580A84C73811E1839F75d86d75d88cCa0c241fF4',
  },
  {
    id: 'quick-qi-quick',
    name: 'QI-QUICK LP',
    token: 'QI-QUICK QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x25d56E2416f20De1Efb1F18fd06dD12eFeC3D3D0',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickQI-QUICK',
    earnedTokenAddress: '0x1d7A7182043F521ab57Fe07a8420Fee9843E365e',
    earnContractAddress: '0x1d7A7182043F521ab57Fe07a8420Fee9843E365e',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-qi-quick',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['QI', 'QUICK'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x580A84C73811E1839F75d86d75d88cCa0c241fF4/0x831753DD7087CaC61aB5644b308642cc1c33Dc13',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x831753DD7087CaC61aB5644b308642cc1c33Dc13&outputCurrency=0x580A84C73811E1839F75d86d75d88cCa0c241fF4',
  },
  {
    id: 'quick-usdc-mimatic',
    name: 'MAI-USDC LP',
    token: 'MAI-USDC QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x160532D2536175d65C03B97b0630A9802c274daD',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickUSDC-miMATIC',
    earnedTokenAddress: '0xf62c521c4fb3Fbc20EaB50E1871A90A53632f22e',
    earnContractAddress: '0xf62c521c4fb3Fbc20EaB50E1871A90A53632f22e',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-usdc-mimatic',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['MAI', 'USDC'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x2791bca1f2de4661ed88a30c99a7a9449aa84174/0xa3Fa99A148fA48D14Ed51d610c367C61876997F1',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x2791bca1f2de4661ed88a30c99a7a9449aa84174&outputCurrency=0xa3Fa99A148fA48D14Ed51d610c367C61876997F1',
  },
  {
    id: 'quick-polybunny-eth',
    name: 'polyBUNNY-ETH LP',
    token: 'polyBUNNY-ETH LP',
    tokenDescription: 'Quickswap',
    tokenAddress: '0x62052b489Cb5bC72a9DC8EEAE4B24FD50639921a',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickpolyBUNNY-ETH',
    earnedTokenAddress: '0x6C4593D4f91c790404cCEe5007E929d2b7bF45bD',
    earnContractAddress: '0x6C4593D4f91c790404cCEe5007E929d2b7bF45bD',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-polybunny-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['polyBUNNY', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619/0x4C16f69302CcB511c5Fac682c7626B9eF0Dc126a',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0x4C16f69302CcB511c5Fac682c7626B9eF0Dc126a',
  },
  {
    id: 'quick-polybunny-quick',
    name: 'polyBUNNY-QUICK LP',
    token: 'polyBUNNY-QUICK LP',
    tokenDescription: 'Quickswap',
    tokenAddress: '0x976b7B7fE4293111CaCd946C422a64f24A223564',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickpolyBUNNY-QUICK',
    earnedTokenAddress: '0x0e229c6381f00F4be47a9529CBDde18016dD9369',
    earnContractAddress: '0x0e229c6381f00F4be47a9529CBDde18016dD9369',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-polybunny-quick',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['polyBUNNY', 'QUICK'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x831753dd7087cac61ab5644b308642cc1c33dc13/0x4C16f69302CcB511c5Fac682c7626B9eF0Dc126a',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0x4C16f69302CcB511c5Fac682c7626B9eF0Dc126a',
  },
  {
    id: 'boneswap-quick-wmatic-bone',
    name: 'BONE-MATIC LP',
    token: 'BONE-MATIC LP',
    tokenDescription: 'Quickswap (BoneSwap)',
    tokenAddress: '0x2d10b334646F6e61F2556c0f7eda2fF3D82d001e',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBoneSwapQuickBONE-WMATIC',
    earnedTokenAddress: '0xe9CAf4DfeaB51114a619C8104C38a309ccA236E1',
    earnContractAddress: '0xe9CAf4DfeaB51114a619C8104C38a309ccA236E1',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boneswap-quick-wmatic-bone',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'BoneSwap',
    assets: ['BONEswap', 'MATIC'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x80244C2441779361F35803b8C711C6c8fC6054a3/0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0x80244C2441779361F35803b8C711C6c8fC6054a3',
  },
  {
    id: 'boneswap-sushi-wmatic-bone',
    name: 'BONE-MATIC SLP',
    token: 'BONE-MATIC SLP',
    tokenDescription: 'SushiSwap (BoneSwap)',
    tokenAddress: '0x4026895A93D720083E4469eE675156A8Ff8D3853',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBoneSwapSushiBONE-WMATIC',
    earnedTokenAddress: '0x9fc153db31a5CeF1Cd326De31FEb37C7499eebC8',
    earnContractAddress: '0x9fc153db31a5CeF1Cd326De31FEb37C7499eebC8',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boneswap-sushi-wmatic-bone',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'BoneSwap',
    assets: ['BONEswap', 'MATIC'],
    callFee: 0.5,
    addLiquidityUrl: 'https://app.sushi.com/add/ETH/0x80244C2441779361F35803b8C711C6c8fC6054a3',
    buyTokenUrl:
      'https://app.sushi.com/swap?inputCurrency=ETH&outputCurrency=0x80244C2441779361F35803b8C711C6c8fC6054a3',
  },
  {
    id: 'boneswap-ape-wmatic-bone',
    name: 'BONE-MATIC ALP',
    token: 'BONE-MATIC ALP',
    tokenDescription: 'ApeSwap (BoneSwap)',
    tokenAddress: '0xbCD83aB205A14648674FE67c97AAD89B261cBaC6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBoneSwapApeBONE-WMATIC',
    earnedTokenAddress: '0x7D59B0cAC35d431fe5B2F3aAED2FB70050B2113D',
    earnContractAddress: '0x7D59B0cAC35d431fe5B2F3aAED2FB70050B2113D',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boneswap-ape-wmatic-bone',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'BoneSwap',
    assets: ['BONEswap', 'MATIC'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://app.apeswap.finance/add/ETH/0x80244C2441779361F35803b8C711C6c8fC6054a3',
    buyTokenUrl:
      'https://app.apeswap.finance/#/swap?outputCurrency=0x80244C2441779361F35803b8C711C6c8fC6054a3',
  },
  {
    id: 'boneswap-quick-usdc-bone',
    name: 'BONE-USDC LP',
    token: 'BONE-USDC LP',
    tokenDescription: 'Quickswap (BoneSwap)',
    tokenAddress: '0xc4DA04f471210063f8C79a09D6152D63085451a5',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBoneSwapQuickBONE-USDC',
    earnedTokenAddress: '0x1001844Cb9Bc3B89a60ce4f4773dBa3B27115230',
    earnContractAddress: '0x1001844Cb9Bc3B89a60ce4f4773dBa3B27115230',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boneswap-quick-usdc-bone',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'BoneSwap',
    assets: ['BONEswap', 'USDC'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x80244C2441779361F35803b8C711C6c8fC6054a3/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0x80244C2441779361F35803b8C711C6c8fC6054a3',
  },
  {
    id: 'polyyeld-quick-wmatic-yeld-eol',
    name: 'YELD-MATIC QLP',
    token: 'YELD-MATIC QLP',
    tokenDescription: 'Quickswap (Polyyeld)',
    tokenAddress: '0xef71F798c15f8C9732488e109BB511144088bECe',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPolyyeldWMATIC-YELD',
    earnedTokenAddress: '0xbea5D2Aed651F579fa3e7ADAA2eC51276B2ea420',
    earnContractAddress: '0xbea5D2Aed651F579fa3e7ADAA2eC51276B2ea420',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'polyyeld-quick-wmatic-yeld',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Polyyeld',
    assets: ['MATIC', 'YELD'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0xd0f3121A190d85dE0AB6131f2bCEcdbfcfB38891/0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0xd0f3121A190d85dE0AB6131f2bCEcdbfcfB38891&outputCurrency=0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
  },
  {
    id: 'dfyn-route-dfyn',
    name: 'ROUTE-DFYN LP',
    token: 'ROUTE-DFYN DLP',
    tokenDescription: 'DFyn',
    tokenAddress: '0xB0dc320ea9eea823a150763AbB4A7bA8286Cd08B',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooDFYNROUTE-DFYN',
    earnedTokenAddress: '0xad88D6B731DCb567a3E13407f1E7B0649Bad82D2',
    earnContractAddress: '0xad88D6B731DCb567a3E13407f1E7B0649Bad82D2',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'dfyn-route-dfyn',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'DFyn',
    assets: ['ROUTE', 'DFYN'],
    callFee: 0.05,
    addLiquidityUrl:
      'https://exchange.dfyn.network/#/add/0x16ECCfDbb4eE1A85A33f3A9B21175Cd7Ae753dB4/0xC168E40227E4ebD8C1caE80F7a55a4F0e6D66C97',
    buyTokenUrl:
      'https://exchange.dfyn.network/#/swap?inputCurrency=0x16ECCfDbb4eE1A85A33f3A9B21175Cd7Ae753dB4&outputCurrency=0xC168E40227E4ebD8C1caE80F7a55a4F0e6D66C97',
  },
  {
    id: 'dfyn-sx-dfyn',
    name: 'SX-DFYN LP',
    token: 'SX-DFYN DLP',
    tokenDescription: 'DFyn',
    tokenAddress: '0x863325397751C51f85818A7958a7Dd45209bf501',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooDFYNSX-DFYN',
    earnedTokenAddress: '0x970283Df294f9cedD2187bf84782AB75617Be31c',
    earnContractAddress: '0x970283Df294f9cedD2187bf84782AB75617Be31c',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'dfyn-sx-dfyn',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'DFyn',
    assets: ['SX', 'DFYN'],
    callFee: 0.05,
    addLiquidityUrl:
      'https://exchange.dfyn.network/#/add/0x840195888Db4D6A99ED9F73FcD3B225Bb3cB1A79/0xC168E40227E4ebD8C1caE80F7a55a4F0e6D66C97',
    buyTokenUrl:
      'https://exchange.dfyn.network/#/swap?inputCurrency=0x840195888Db4D6A99ED9F73FcD3B225Bb3cB1A79&outputCurrency=0xC168E40227E4ebD8C1caE80F7a55a4F0e6D66C97',
  },
  {
    id: 'dfyn-ez-dfyn',
    name: 'EZ-DFYN LP',
    token: 'EZ-DFYN DLP',
    tokenDescription: 'DFyn',
    tokenAddress: '0x3a89F0B87E6249Cc44A9420EfBe96ed6173aD0Dc',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooDFYNEZ-DFYN',
    earnedTokenAddress: '0x0E765383bea3a166A87a042132422f5F29fDb217',
    earnContractAddress: '0x0E765383bea3a166A87a042132422f5F29fDb217',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'dfyn-ez-dfyn',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'DFyn',
    assets: ['EZ', 'DFYN'],
    callFee: 0.05,
    addLiquidityUrl:
      'https://exchange.dfyn.network/#/add/0x34C1b299A74588D6Abdc1b85A53345A48428a521/0xC168E40227E4ebD8C1caE80F7a55a4F0e6D66C97',
    buyTokenUrl:
      'https://exchange.dfyn.network/#/swap?inputCurrency=0x34C1b299A74588D6Abdc1b85A53345A48428a521&outputCurrency=0xC168E40227E4ebD8C1caE80F7a55a4F0e6D66C97',
  },
  {
    id: 'dfyn-uft-dfyn',
    name: 'UFT-DFYN LP',
    token: 'UFT-DFYN DLP',
    tokenDescription: 'DFyn',
    tokenAddress: '0x8f07eaC653cd812b3Ac691729CD16616E232de68',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooDFYNUFT-DFYN',
    earnedTokenAddress: '0xD239D193809b04fD24CB0Cd56A99A3F9dB069C9d',
    earnContractAddress: '0xD239D193809b04fD24CB0Cd56A99A3F9dB069C9d',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'dfyn-uft-dfyn',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'DFyn',
    assets: ['UFT', 'DFYN'],
    callFee: 0.05,
    addLiquidityUrl:
      'https://exchange.dfyn.network/#/add/0x5B4CF2C120A9702225814E18543ee658c5f8631e/0xC168E40227E4ebD8C1caE80F7a55a4F0e6D66C97',
    buyTokenUrl:
      'https://exchange.dfyn.network/#/swap?inputCurrency=0x5B4CF2C120A9702225814E18543ee658c5f8631e&outputCurrency=0xC168E40227E4ebD8C1caE80F7a55a4F0e6D66C97',
  },
  {
    id: 'polycat-dfyn-fish-matic',
    logo: 'polygon/FISH-MATIC.svg',
    name: 'FISH-MATIC LP',
    token: 'FISH-MATIC DLP',
    tokenDescription: 'DFyn',
    tokenAddress: '0x9e2Fbb31fBd68472f6cd54A1635b8cd64d78FC1C',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPolycatDfynFISH-MATIC',
    earnedTokenAddress: '0x9f3B96a2Dd55aa904bC5476Ffe66E74a53f6b420',
    earnContractAddress: '0x9f3B96a2Dd55aa904bC5476Ffe66E74a53f6b420',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'polycat-dfyn-fish-matic',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Polycat',
    assets: ['FISH', 'WMATIC_DFYN'],
    callFee: 0.05,
    addLiquidityUrl:
      'https://exchange.dfyn.network/#/add/0x3a3df212b7aa91aa0402b9035b098891d276572b/ETH',
    buyTokenUrl:
      'https://exchange.dfyn.network/#/swap?inputCurrency=0x3a3df212b7aa91aa0402b9035b098891d276572b&outputCurrency=ETH',
  },
  {
    id: 'polypup-bone',
    logo: 'single-assets/BONE.png',
    name: 'BONE',
    token: 'BONE',
    tokenDescription: 'Polypup',
    tokenAddress: '0x6bb45cEAC714c52342Ef73ec663479da35934bf7',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPolypupBONE',
    earnedTokenAddress: '0x8Fa291074B9E28055fFdBCd4C1C1Dad67B9a130A',
    earnContractAddress: '0x8Fa291074B9E28055fFdBCd4C1C1Dad67B9a130A',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'BONE',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Polypup',
    assets: ['BONE'],
    callFee: 0.5,
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0x6bb45cEAC714c52342Ef73ec663479da35934bf7',
  },
  {
    id: 'polypup-pup',
    logo: 'single-assets/PUP.png',
    name: 'PUP',
    token: 'PUP',
    tokenDescription: 'Polypup',
    tokenAddress: '0xcFe2cF35D2bDDE84967e67d00aD74237e234CE59',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPolypupPUP',
    earnedTokenAddress: '0xBe1C35d3349555Ba9eEa38AB1A21a6Db0Bb0fCdD',
    earnContractAddress: '0xBe1C35d3349555Ba9eEa38AB1A21a6Db0Bb0fCdD',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'PUP',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Polypup',
    assets: ['PUP'],
    callFee: 0.5,
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0xcFe2cF35D2bDDE84967e67d00aD74237e234CE59',
  },
  {
    id: 'polypup-wmatic-bone',
    name: 'BONE-MATIC QLP',
    token: 'BONE-MATIC QLP',
    tokenDescription: 'Polypup',
    tokenAddress: '0xeb168C9D678dC34D64337F4eA12cf14ed6fbE34B',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPolypupWMATIC-BONE',
    earnedTokenAddress: '0x6C433f102A6b8582a43222e335C73873538161b7',
    earnContractAddress: '0x6C433f102A6b8582a43222e335C73873538161b7',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'polypup-wmatic-bone',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Polypup',
    assets: ['MATIC', 'BONE'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270/0x6bb45cEAC714c52342Ef73ec663479da35934bf7',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270&outputCurrency=0x6bb45cEAC714c52342Ef73ec663479da35934bf7',
  },
  {
    id: 'polypup-usdc-pup',
    name: 'PUP-USDC QLP',
    token: 'PUP-USDC QLP',
    tokenDescription: 'Polypup',
    tokenAddress: '0x767f8BD67a5f133BdDF3b285c5E2FD3D157A2cdC',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPolypupUSDC-PUP',
    earnedTokenAddress: '0xB1893a79fcCe73aEfc878e5AC918698Ee0110444',
    earnContractAddress: '0xB1893a79fcCe73aEfc878e5AC918698Ee0110444',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'polypup-usdc-pup',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Polypup',
    assets: ['PUP', 'USDC'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/0xcFe2cF35D2bDDE84967e67d00aD74237e234CE59',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174&outputCurrency=0xcFe2cF35D2bDDE84967e67d00aD74237e234CE59',
  },
  {
    id: 'polypup-wmatic-pup',
    name: 'PUP-WMATIC QLP',
    token: 'PUP-WMATIC QLP',
    tokenDescription: 'Polypup',
    tokenAddress: '0xBC68d2A5920c4ffaEa20E2BE48a0E09055481976',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPolypupWMATIC-PUP',
    earnedTokenAddress: '0x37884333d34eeE3EAe83439CE4608E69E7081116',
    earnContractAddress: '0x37884333d34eeE3EAe83439CE4608E69E7081116',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'polypup-wmatic-pup',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Polypup',
    assets: ['PUP', 'MATIC'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270/0xcFe2cF35D2bDDE84967e67d00aD74237e234CE59',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270&outputCurrency=0xcFe2cF35D2bDDE84967e67d00aD74237e234CE59',
  },
  {
    id: 'ape-matic-usdt',
    name: 'MATIC-USDT ALP',
    token: 'MATIC-USDT ALP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0x65D43B64E3B31965Cd5EA367D4c2b94c03084797',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeSwapMATIC-USDT',
    earnedTokenAddress: '0x1BE356364a1e849af2F7a513Fc46dAB6063Db485',
    earnContractAddress: '0x1BE356364a1e849af2F7a513Fc46dAB6063Db485',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'ape-matic-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['MATIC', 'USDT'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://app.apeswap.finance/add/ETH/0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
    buyTokenUrl:
      'https://app.apeswap.finance/#/swap?outputCurrency=0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
  },
  {
    id: 'ape-matic-dai',
    name: 'MATIC-DAI ALP',
    token: 'MATIC-DAI ALP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0xd32f3139A214034A0f9777c87eE0a064c1FF6AE2',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeSwapMATIC-DAI',
    earnedTokenAddress: '0x584611DA226b4d4C0c4D880E6f1E0c0E8522f3AE',
    earnContractAddress: '0x584611DA226b4d4C0c4D880E6f1E0c0E8522f3AE',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'ape-matic-dai',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['MATIC', 'DAI'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://app.apeswap.finance/add/ETH/0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
    buyTokenUrl:
      'https://app.apeswap.finance/#/swap?outputCurrency=0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
  },
  {
    id: 'polypup-weth-pup-eol',
    name: 'PUP-ETH QLP',
    token: 'PUP-ETH QLP',
    tokenDescription: 'Polypup',
    tokenAddress: '0x0d6fF4e5a446C3e7ae84C838bbF5e3b6b2E26093',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPolypupWETH-PUP',
    earnedTokenAddress: '0x4D42bD06425179e53498D364427Af4Bf50d27658',
    earnContractAddress: '0x4D42bD06425179e53498D364427Af4Bf50d27658',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'polypup-weth-pup-eol',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Polypup',
    assets: ['PUP', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0xcFe2cF35D2bDDE84967e67d00aD74237e234CE59',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0xcFe2cF35D2bDDE84967e67d00aD74237e234CE59',
  },
  {
    id: 'quick-eth-ramp',
    name: 'RAMP-ETH LP',
    token: 'RAMP-ETH QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xe55739E1fEb9F9aED4Ce34830a06cA6Cc37494A0',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickETH-RAMP',
    earnedTokenAddress: '0x94242E121F056FA7F0892452e6a17678124981c1',
    earnContractAddress: '0x94242E121F056FA7F0892452e6a17678124981c1',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-eth-ramp',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['RAMP', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0xaECeBfcF604AD245Eaf0D5BD68459C3a7A6399c2/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0xaECeBfcF604AD245Eaf0D5BD68459C3a7A6399c2',
  },
  {
    id: 'quick-usdc-rusd',
    name: 'rUSD-USDC LP',
    token: 'rUSD-USDC QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x5EF8747d1dc4839e92283794a10d448357973aC0',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickUSDC-rUSD',
    earnedTokenAddress: '0x2b17aD11c5e9553c79a18F33Df4dE565eF0ad5b0',
    earnContractAddress: '0x2b17aD11c5e9553c79a18F33Df4dE565eF0ad5b0',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-usdc-rusd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['rUSD', 'USDC'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0xfC40a4F89b410a1b855b5e205064a38fC29F5eb5/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174&outputCurrency=0xfC40a4F89b410a1b855b5e205064a38fC29F5eb5',
  },
  {
    id: 'polyyeld-ape-wmatic-yeld',
    name: 'YELD-MATIC ALP',
    token: 'YELD-MATIC ALP',
    tokenDescription: 'ApeSwap (Polyyeld)',
    tokenAddress: '0xC1Dd63cA154837AC4356D888F7c10fbBe442407e',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPolyyeldApeWMATIC-YELD',
    earnedTokenAddress: '0xd73AEF83c08264C5600C3a17f009B6f8c0226221',
    earnContractAddress: '0xd73AEF83c08264C5600C3a17f009B6f8c0226221',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'polyyeld-ape-wmatic-yeld',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Polyyeld',
    assets: ['MATIC', 'YELD'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://app.apeswap.finance/add/0xd0f3121A190d85dE0AB6131f2bCEcdbfcfB38891/0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    buyTokenUrl:
      'https://app.apeswap.finance/#/swap?inputCurrency=0xd0f3121A190d85dE0AB6131f2bCEcdbfcfB38891&outputCurrency=0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
  },
  {
    id: 'sushi-wbtc-ibbtc',
    name: 'ibBTC-WBTC SLP',
    token: 'ibBTC-WBTC SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0x8F8e95Ff4B4c5E354ccB005c6B0278492D7B5907',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiWBTC-ibBTC',
    earnedTokenAddress: '0xD35B3733c2ceaf3635bE246B2c6C42f10e5b6B78',
    earnContractAddress: '0xD35B3733c2ceaf3635bE246B2c6C42f10e5b6B78',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-wbtc-ibbtc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['ibBTC', 'WBTC'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://app.sushi.com/add/0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6/0x4EaC4c4e9050464067D673102F8E24b2FccEB350',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6&outputCurrency=0x4EaC4c4e9050464067D673102F8E24b2FccEB350',
  },
  {
    id: 'ape-eth-matic',
    name: 'ETH-MATIC ALP',
    token: 'ETH-MATIC ALP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0x6Cf8654e85AB489cA7e70189046D507ebA233613',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeSwapMATIC-ETH',
    earnedTokenAddress: '0xc24Cf5fA29E619f2D5ccbEC46F2295876c3722ff',
    earnContractAddress: '0xc24Cf5fA29E619f2D5ccbEC46F2295876c3722ff',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'ape-eth-matic',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['ETH', 'MATIC'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://app.apeswap.finance/add/ETH/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    buyTokenUrl:
      'https://app.apeswap.finance/#/swap?outputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
  },
  {
    id: 'ape-btc-matic',
    name: 'BTC-MATIC ALP',
    token: 'BTC-MATIC ALP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0xe82635a105c520fd58e597181cBf754961d51E3e',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeSwapMATIC-BTC',
    earnedTokenAddress: '0x76F0e4a08c1e85d020dfD7C66B991ecd4A7551AF',
    earnContractAddress: '0x76F0e4a08c1e85d020dfD7C66B991ecd4A7551AF',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'ape-btc-matic',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['WBTC', 'MATIC'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://app.apeswap.finance/add/ETH/0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
    buyTokenUrl:
      'https://app.apeswap.finance/#/swap?outputCurrency=0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
  },
  {
    id: 'ape-bnb-matic',
    name: 'BNB-MATIC ALP',
    token: 'BNB-MATIC ALP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0x0359001070cF696D5993E0697335157a6f7dB289',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeSwapMATIC-BNB',
    earnedTokenAddress: '0x6888f67662d1f442C4428129e0Bdb27a275e0a65',
    earnContractAddress: '0x6888f67662d1f442C4428129e0Bdb27a275e0a65',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'ape-bnb-matic',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['BNB', 'MATIC'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://app.apeswap.finance/add/ETH/0xA649325Aa7C5093d12D6F98EB4378deAe68CE23F',
    buyTokenUrl:
      'https://app.apeswap.finance/#/swap?outputCurrency=0xA649325Aa7C5093d12D6F98EB4378deAe68CE23F',
  },
  {
    id: 'ape-banana-matic',
    name: 'BANANA-MATIC ALP',
    token: 'BANANA-MATIC ALP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0x034293F21F1cCE5908BC605CE5850dF2b1059aC0',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeSwapBANANA-MATIC',
    earnedTokenAddress: '0xADA7F98fb2594E76914EB593e74B348A498Ea5Bd',
    earnContractAddress: '0xADA7F98fb2594E76914EB593e74B348A498Ea5Bd',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'ape-banana-matic',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['BANANA', 'MATIC'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://app.apeswap.finance/add/ETH/0x5d47bAbA0d66083C52009271faF3F50DCc01023C',
    buyTokenUrl:
      'https://app.apeswap.finance/#/swap?outputCurrency=0x5d47bAbA0d66083C52009271faF3F50DCc01023C',
  },
  {
    id: 'polyyeld-sushi-wmatic-yeld',
    name: 'YELD-MATIC SLP',
    token: 'YELD-MATIC SLP',
    tokenDescription: 'SushiSwap (Polyyeld)',
    tokenAddress: '0xdD650C8d274474FF1af1152B3B27f2702AcA8a98',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPolyyeldSushiWMATIC-YELD',
    earnedTokenAddress: '0x0Ca850eEfE051ED244846A2939e218ec6D44a0b2',
    earnContractAddress: '0x0Ca850eEfE051ED244846A2939e218ec6D44a0b2',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'polyyeld-sushi-wmatic-yeld',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Polyyeld',
    assets: ['MATIC', 'YELD'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://app.sushi.com/add/0xd0f3121A190d85dE0AB6131f2bCEcdbfcfB38891/0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0xd0f3121A190d85dE0AB6131f2bCEcdbfcfB38891&outputCurrency=0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
  },
  {
    id: 'polyyeld-quick-usdc-yeld',
    name: 'YELD-USDC QLP',
    token: 'YELD-USDC QLP',
    tokenDescription: 'Quickswap (Polyyeld)',
    tokenAddress: '0xAD8f4c66b28756458Fc419AA67aD2207A21A6997',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPolyyeldUSDC-YELD',
    earnedTokenAddress: '0x9a8390F3F74E7f367B5f948dd04495B67a54a5F4',
    earnContractAddress: '0x9a8390F3F74E7f367B5f948dd04495B67a54a5F4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'polyyeld-quick-usdc-yeld',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Polyyeld',
    assets: ['USDC', 'YELD'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0xd0f3121A190d85dE0AB6131f2bCEcdbfcfB38891/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0xd0f3121A190d85dE0AB6131f2bCEcdbfcfB38891&outputCurrency=0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
  },
  {
    id: 'sushi-usdc-bifi',
    name: 'BIFI-USDC SLP',
    token: 'BIFI-USDC SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0x180237bd326d5245D0898336F54b3c8012c5c62f',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiUSDC-BIFI',
    earnedTokenAddress: '0x03F69AAF4c8512f533Da46cC9eFd49C4969e3CB8',
    earnContractAddress: '0x03F69AAF4c8512f533Da46cC9eFd49C4969e3CB8',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-usdc-bifi',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['USDC', 'BIFI'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://app.sushi.com/add/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/0xFbdd194376de19a88118e84E279b977f165d01b8',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174&outputCurrency=0xFbdd194376de19a88118e84E279b977f165d01b8',
  },
  {
    id: 'quick-pbnb-quick',
    name: 'pBNB-QUICK QLP',
    token: 'pBNB-QUICK QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x53e27DaDf6473d062717be8807C453Af212c7102',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickPBNB-QUICK',
    earnedTokenAddress: '0x1A90Ea15e00a6c647478e86e3A3DB1aC1eB23cE5',
    earnContractAddress: '0x1A90Ea15e00a6c647478e86e3A3DB1aC1eB23cE5',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-pbnb-quick',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['PBNB', 'QUICK'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0xE55cb144E02Cffb6fBD65d9a4BD62378998Bc267/0x831753DD7087CaC61aB5644b308642cc1c33Dc13',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0xE55cb144E02Cffb6fBD65d9a4BD62378998Bc267&outputCurrency=0x831753DD7087CaC61aB5644b308642cc1c33Dc13',
  },
  {
    id: 'quick-usdc-pbnb',
    name: 'pBNB-USDC QLP',
    token: 'pBNB-USDC QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xE55cb144E02Cffb6fBD65d9a4BD62378998Bc267',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickUSDC-PBNB',
    earnedTokenAddress: '0x61f55dc5243398D57acd5d6265e228da65C4Cb52',
    earnContractAddress: '0x61f55dc5243398D57acd5d6265e228da65C4Cb52',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-usdc-pbnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['USDC', 'PBNB'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0xE55cb144E02Cffb6fBD65d9a4BD62378998Bc267/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0xE55cb144E02Cffb6fBD65d9a4BD62378998Bc267&outputCurrency=0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
  },
  {
    id: 'quick-dai-usdt',
    name: 'DAI-USDT LP',
    token: 'DAI-USDT QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x59153f27eeFE07E5eCE4f9304EBBa1DA6F53CA88',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickDAI-USDT',
    earnedTokenAddress: '0x8F1F1FB23A208041e1f4Bf4A3b4E165bcCA25Bbb',
    earnContractAddress: '0x8F1F1FB23A208041e1f4Bf4A3b4E165bcCA25Bbb',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-dai-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['DAI', 'USDT'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063/0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063&outputCurrency=0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
  },
  {
    id: 'quick-eth-fff',
    name: 'FFF-ETH LP',
    token: 'FFF-ETH QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x4935270Aa19bA0B88Fe99eA9FeB10519feAfA758',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickETH-FFF',
    earnedTokenAddress: '0xa5b0E0f38BC86723a9893B828a4B9595ecb22F42',
    earnContractAddress: '0xa5b0E0f38BC86723a9893B828a4B9595ecb22F42',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-eth-fff',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['FFF', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0x9aceb6f749396d1930abc9e263efc449e5e82c13',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0x9aceb6f749396d1930abc9e263efc449e5e82c13',
  },
  {
    id: 'wexpoly-wbtc-usdc',
    name: 'WBTC-USDC LP',
    token: 'WBTC-USDC WLP',
    tokenDescription: 'WaultFinance',
    tokenAddress: '0x30eEf213D4B9C809f5776Ae56cC39f02f19F742f',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooWexPolyWBTC-USDC',
    earnedTokenAddress: '0x5c7fd860fC0072cFef2Cc4aB17CC7fF25B639d8b',
    earnContractAddress: '0x5c7fd860fC0072cFef2Cc4aB17CC7fF25B639d8b',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wexpoly-wbtc-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'WaultFinance',
    assets: ['WBTC', 'USDC'],
    callFee: 0.05,
    addLiquidityUrl:
      'https://swap.wault.finance/polygon/#/add/0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
  },
  {
    id: 'wexpoly-wbtc-eth',
    name: 'WBTC-ETH LP',
    token: 'WBTC-ETH WLP',
    tokenDescription: 'WaultFinance',
    tokenAddress: '0x30AdBCAb9Fbb4436109fcf3058D1Af27E6E33F3F',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooWexPolyWBTC-ETH',
    earnedTokenAddress: '0x75A59e8d6611e90e7A8e439Cb59D9f78e738d16F',
    earnContractAddress: '0x75A59e8d6611e90e7A8e439Cb59D9f78e738d16F',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wexpoly-wbtc-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'WaultFinance',
    assets: ['WBTC', 'ETH'],
    callFee: 0.05,
    addLiquidityUrl:
      'https://swap.wault.finance/polygon/#/add/0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
  },
  {
    id: 'wexpoly-matic-eth',
    logo: 'polygon/ETH-MATIC.png',
    name: 'MATIC-ETH LP',
    token: 'MATIC-ETH WLP',
    tokenDescription: 'WaultFinance',
    tokenAddress: '0x679B8Ab80F298bF802fB294137765C6386D43dcA',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooWexPolyMATIC-ETH',
    earnedTokenAddress: '0xCB171DF46CA6FF7AfEF6c4935128204435E4F05C',
    earnContractAddress: '0xCB171DF46CA6FF7AfEF6c4935128204435E4F05C',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wexpoly-matic-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'WaultFinance',
    assets: ['MATIC', 'ETH'],
    callFee: 0.05,
    addLiquidityUrl:
      'https://swap.wault.finance/polygon/#/add/MATIC/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
  },
  {
    id: 'wexpoly-polydoge-matic',
    name: 'POLYDOGE-MATIC LP',
    token: 'POLYDOGE-MATIC WLP',
    tokenDescription: 'WaultFinance',
    tokenAddress: '0x1a881D021d346C5433B2cf8FB46Ee00Dec89070d',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MooWexPolyPOLYDOGE-MATIC',
    earnedTokenAddress: '0xdf4E615e05713f9Be712bb999B3190Fd238bb77A',
    earnContractAddress: '0xdf4E615e05713f9Be712bb999B3190Fd238bb77A',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wexpoly-polydoge-matic',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'WaultFinance',
    assets: ['PolyDoge', 'MATIC'],
    callFee: 0.05,
    addLiquidityUrl:
      'https://swap.wault.finance/polygon/#/add/MATIC/0x8A953CfE442c5E8855cc6c61b1293FA648BAE472',
  },
  {
    id: 'wexpoly-snx-eth',
    name: 'SNX-ETH LP',
    token: 'SNX-ETH WLP',
    tokenDescription: 'WaultFinance',
    tokenAddress: '0x2857Ec555c1E4fA20982AcBf985b8b8807157A5B',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MooWexPolySNX-ETH',
    earnedTokenAddress: '0xf7e1226F6f98D1981e88Da328347F0E2131A2864',
    earnContractAddress: '0xf7e1226F6f98D1981e88Da328347F0E2131A2864',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wexpoly-snx-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'WaultFinance',
    assets: ['SNX', 'ETH'],
    callFee: 0.05,
    addLiquidityUrl:
      'https://swap.wault.finance/polygon/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0x50B728D8D964fd00C2d0AAD81718b71311feF68a',
  },
  {
    id: 'wexpoly-link-eth',
    name: 'LINK-ETH LP',
    token: 'LINK-ETH WLP',
    tokenDescription: 'WaultFinance',
    tokenAddress: '0x70bF5ffCC6090A8D243FD05485Ec4c084BD10Ae5',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MooWexPolyLINK-ETH',
    earnedTokenAddress: '0x23Ee6ED971ae7731F53913D9f8a45C7C1af3D6D5',
    earnContractAddress: '0x23Ee6ED971ae7731F53913D9f8a45C7C1af3D6D5',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wexpoly-link-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'WaultFinance',
    assets: ['LINK', 'ETH'],
    callFee: 0.05,
    addLiquidityUrl:
      'https://swap.wault.finance/polygon/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39',
  },
  {
    id: 'wexpoly-aave-eth',
    name: 'AAVE-ETH LP',
    token: 'AAVE-ETH WLP',
    tokenDescription: 'WaultFinance',
    tokenAddress: '0x58401db5eD194cBA60f2b7D59b9619D54e0B493c',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MooWexPolyAAVE-ETH',
    earnedTokenAddress: '0xc4cC677bb8b7E6EEA6409Ee2af9F434BAB004192',
    earnContractAddress: '0xc4cC677bb8b7E6EEA6409Ee2af9F434BAB004192',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wexpoly-aave-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'WaultFinance',
    assets: ['AAVE', 'ETH'],
    callFee: 0.05,
    addLiquidityUrl:
      'https://swap.wault.finance/polygon/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0xD6DF932A45C0f255f85145f286eA0b292B21C90B',
  },
  {
    id: 'wexpoly-dai-eth',
    name: 'DAI-ETH LP',
    token: 'DAI-ETH WLP',
    tokenDescription: 'WaultFinance',
    tokenAddress: '0xaEF35d720C56eF3c259BCCdB6931F331c7f15b3F',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MooWexPolyDAI-ETH',
    earnedTokenAddress: '0x3a3a9784Af130d692E19A3f1C1b13eF44301D0f7',
    earnContractAddress: '0x3a3a9784Af130d692E19A3f1C1b13eF44301D0f7',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wexpoly-dai-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'WaultFinance',
    assets: ['DAI', 'ETH'],
    callFee: 0.05,
    addLiquidityUrl:
      'https://swap.wault.finance/polygon/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
  },
  {
    id: 'wexpoly-usdc-dai',
    name: 'USDC-DAI LP',
    token: 'USDC-DAI WLP',
    tokenDescription: 'WaultFinance',
    tokenAddress: '0x8E5c5AE2542116b5715b12bb2d391A770a607075',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MooWexPolyUSDC-DAI',
    earnedTokenAddress: '0x5E7156F7c0B5E2005000C38beb38540ba9c283df',
    earnContractAddress: '0x5E7156F7c0B5E2005000C38beb38540ba9c283df',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wexpoly-usdc-dai',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'WaultFinance',
    assets: ['DAI', 'USDC'],
    callFee: 0.05,
    addLiquidityUrl:
      'https://swap.wault.finance/polygon/#/add/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
  },
  {
    id: 'wexpoly-matic-usdc',
    logo: 'polygon/MATIC-USDC.png',
    name: 'MATIC-USDC LP',
    token: 'MATIC-USDC WLP',
    tokenDescription: 'WaultFinance',
    tokenAddress: '0x8312A29A91D9Fac706F4d2ADEB1FA4540faD1673',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooWexPolyMATIC-USDC',
    earnedTokenAddress: '0xeCBb84E73760d0E22BDBeE79490c691386B5008F',
    earnContractAddress: '0xeCBb84E73760d0E22BDBeE79490c691386B5008F',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wexpoly-matic-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'WaultFinance',
    assets: ['MATIC', 'USDC'],
    callFee: 0.05,
    addLiquidityUrl:
      'https://swap.wault.finance/polygon/#/add/MATIC/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
  },
  {
    id: 'wexpoly-usdc-usdt',
    logo: 'polygon/USDC-USDT.png',
    name: 'USDC-USDT LP',
    token: 'USDC-USDT WLP',
    tokenDescription: 'WaultFinance',
    tokenAddress: '0x7242e19A0937ac33472febD69462668a4cf5bbC5',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooWexPolyUSDC-USDT',
    earnedTokenAddress: '0x58C55B5675B106b440635E2c550A771f4E256D35',
    earnContractAddress: '0x58C55B5675B106b440635E2c550A771f4E256D35',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wexpoly-usdc-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'WaultFinance',
    assets: ['USDT', 'USDC'],
    callFee: 0.05,
    addLiquidityUrl:
      'https://swap.wault.finance/polygon/#/add/0x2791bca1f2de4661ed88a30c99a7a9449aa84174/0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
  },
  {
    id: 'wexpoly-wex-usdc',
    logo: 'polygon/WEX-USDC.png',
    name: 'WEXpoly-USDC LP',
    token: 'WEXpoly-USDC LP',
    tokenDescription: 'WaultFinance',
    tokenAddress: '0x5DE6a3CcA10d3F788EEdbD4923e31D4658bf6f9a',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooWexPolyWEX-USDC',
    earnedTokenAddress: '0xe3B5a0d5dFDCD6dE7e22a5c2B6b957aB76d2A085',
    earnContractAddress: '0xe3B5a0d5dFDCD6dE7e22a5c2B6b957aB76d2A085',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wexpoly-wex-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'WaultFinance',
    assets: ['WEXpoly', 'USDC'],
    callFee: 0.05,
    addLiquidityUrl:
      'https://swap.wault.finance/polygon/#/add/0x2791bca1f2de4661ed88a30c99a7a9449aa84174/0x4c4BF319237D98a30A929A96112EfFa8DA3510EB',
    buyTokenUrl:
      'https://swap.wault.finance/polygon/#/swap?outputCurrency=0x4c4BF319237D98a30A929A96112EfFa8DA3510EB',
  },
  {
    id: 'wexpoly-wex',
    logo: 'polygon/WEX.png',
    name: 'WEXpoly',
    token: 'WEXpoly',
    tokenDescription: 'WaultFinance',
    tokenAddress: '0x4c4BF319237D98a30A929A96112EfFa8DA3510EB',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooWexPolyWEXpoly',
    earnedTokenAddress: '0x1A1432b90a093CbFFeCb44cf6965e2fd11005373',
    earnContractAddress: '0x1A1432b90a093CbFFeCb44cf6965e2fd11005373',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'WEXpoly',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'WaultFinance',
    assets: ['WEXpoly'],
    callFee: 0.5,
    buyTokenUrl:
      'https://swap.wault.finance/polygon/#/swap?outputCurrency=0x4c4BF319237D98a30A929A96112EfFa8DA3510EB',
  },
  {
    id: 'wexpoly-wex-matic',
    logo: 'polygon/WEX-MATIC.png',
    name: 'WEXpoly-MATIC LP',
    token: 'WEXpoly-MATIC LP',
    tokenDescription: 'WaultFinance',
    tokenAddress: '0xC46991072C7eAC184F7bd69d3122cd1750300b27',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooWexPolyWEX-MATIC',
    earnedTokenAddress: '0xe09888EEab19bce85e67eDC59521F3f290B1BCcE',
    earnContractAddress: '0xe09888EEab19bce85e67eDC59521F3f290B1BCcE',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wexpoly-wex-matic',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'WaultFinance',
    assets: ['WEXpoly', 'MATIC'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://swap.wault.finance/polygon/#/add/0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270/0x4c4BF319237D98a30A929A96112EfFa8DA3510EB',
    buyTokenUrl:
      'https://swap.wault.finance/polygon/#/swap?outputCurrency=0x4c4BF319237D98a30A929A96112EfFa8DA3510EB',
  },
  {
    id: 'wexpoly-bifi-matic',
    logo: 'polygon/BIFI-MATIC.png',
    name: 'BIFI-MATIC LP',
    token: 'BIFI-MATIC LP',
    tokenDescription: 'WaultFinance',
    tokenAddress: '0xE60afed80406190C3AB2C17501d417097Dd741DB',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooWexPolyBIFI-MATIC',
    earnedTokenAddress: '0x247303D4Be227Aa87Bd52F05aDDAD772794DE394',
    earnContractAddress: '0x247303D4Be227Aa87Bd52F05aDDAD772794DE394',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wexpoly-bifi-matic',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'WaultFinance',
    assets: ['BIFI', 'MATIC'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://swap.wault.finance/polygon/#/add/0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270/0xFbdd194376de19a88118e84E279b977f165d01b8',
    buyTokenUrl:
      'https://swap.wault.finance/polygon/#/swap?outputCurrency=0xFbdd194376de19a88118e84E279b977f165d01b8',
  },
  {
    id: 'sushi-eth-wfil',
    logo: 'polygon/ETH-FIL.png',
    name: 'ETH-FIL SLP',
    token: 'ETH-FIL SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0x21Ef14B5580a852477ef31E7Ea9373485bF50377',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiETH-WFIL',
    earnedTokenAddress: '0xA4fB94990f99A8C1290e83d40DB9C648fD868D14',
    earnContractAddress: '0xA4fB94990f99A8C1290e83d40DB9C648fD868D14',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-eth-wfil',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['ETH', 'WFIL'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://app.sushi.com/add/0xEde1B77C0Ccc45BFa949636757cd2cA7eF30137F/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0xEde1B77C0Ccc45BFa949636757cd2cA7eF30137F&outputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
  },
  {
    id: 'quick-usdc-dai',
    logo: 'polygon/USDC-DAI.png',
    name: 'USDC-DAI LP',
    token: 'USDC-DAI QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xf04adBF75cDFc5eD26eeA4bbbb991DB002036Bdd',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickUSDC-DAI',
    earnedTokenAddress: '0x0dFd8c4dd493d8f87Be362878E41537Ca7Ee4d9e',
    earnContractAddress: '0x0dFd8c4dd493d8f87Be362878E41537Ca7Ee4d9e',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-usdc-dai',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['USDC', 'DAI'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174&outputCurrency=0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
  },
  {
    id: 'quick-quick-uni',
    logo: 'polygon/QUICK-UNI.png',
    name: 'QUICK-UNI LP',
    token: 'QUICK-UNI QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x4B4C614b9219397c02296f6F4e2351259840B3C7',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickQUICK-UNI',
    earnedTokenAddress: '0xaF34573353aBd160889889D52d7841B2bBCE7Cf9',
    earnContractAddress: '0xaF34573353aBd160889889D52d7841B2bBCE7Cf9',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-quick-uni',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['QUICK', 'UNI'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x831753DD7087CaC61aB5644b308642cc1c33Dc13/0xb33EaAd8d922B1083446DC23f610c2567fB5180f',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x831753DD7087CaC61aB5644b308642cc1c33Dc13&outputCurrency=0xb33EaAd8d922B1083446DC23f610c2567fB5180f',
  },
  {
    id: 'quick-link-quick',
    logo: 'polygon/LINK-QUICK.png',
    name: 'LINK-QUICK LP',
    token: 'LINK-QUICK QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xDEA8f0F1E6E98C6AEE891601600E5fBa294B5e36',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickLINK-QUICK',
    earnedTokenAddress: '0xdD32ca42a5bab4073D319BC26bb4e951e767Ba6E',
    earnContractAddress: '0xdD32ca42a5bab4073D319BC26bb4e951e767Ba6E',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-link-quick',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['LINK', 'QUICK'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39/0x831753DD7087CaC61aB5644b308642cc1c33Dc13',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39&outputCurrency=0x831753DD7087CaC61aB5644b308642cc1c33Dc13',
  },
  {
    id: 'polycat-fish',
    logo: 'single-assets/FISH.svg',
    name: 'FISH',
    token: 'FISH',
    tokenDescription: 'Polycat',
    tokenAddress: '0x3a3Df212b7AA91Aa0402B9035b098891d276572B',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPolycatFish',
    earnedTokenAddress: '0xcC16BbE4987920a17F5C4a92C1Ab4dbDfB0B9790',
    earnContractAddress: '0xcC16BbE4987920a17F5C4a92C1Ab4dbDfB0B9790',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'FISH',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Polycat',
    assets: ['FISH'],
    withdrawalFee: '0%',
    callFee: 0.5,
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0x3a3Df212b7AA91Aa0402B9035b098891d276572B',
  },
  {
    id: 'quick-usdc-eth',
    logo: 'polygon/USDC-ETH.png',
    name: 'USDC-ETH LP',
    token: 'USDC-ETH QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x853Ee4b2A13f8a742d64C8F088bE7bA2131f670d',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickUSDC-ETH',
    earnedTokenAddress: '0x5d4B83B3011B1E14e55185c5D432987e76f6DE3C',
    earnContractAddress: '0x5d4B83B3011B1E14e55185c5D432987e76f6DE3C',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-usdc-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['USDC', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x2791bca1f2de4661ed88a30c99a7a9449aa84174/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x2791bca1f2de4661ed88a30c99a7a9449aa84174&outputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
  },
  {
    id: 'quick-dai-eth',
    logo: 'polygon/ETH-DAI.png',
    name: 'DAI-ETH LP',
    token: 'DAI-ETH QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x4A35582a710E1F4b2030A3F826DA20BfB6703C09',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickDAI-ETH',
    earnedTokenAddress: '0x9DA4048550C1a73686E594f726310F0b0585fBA3',
    earnContractAddress: '0x9DA4048550C1a73686E594f726310F0b0585fBA3',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-dai-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['DAI', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x8f3cf7ad23cd3cadbd9735aff958023239c6a063&outputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
  },
  {
    id: 'quick-fff-quick',
    logo: 'polygon/FFF-QUICK.png',
    name: 'FFF-QUICK LP',
    token: 'FFF-QUICK QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x2648ec89875d944e38f55925Df77D9Cfe0B01Edd',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickFFF-QUICK',
    earnedTokenAddress: '0x2D4cf116A59e24Dd0aB8821c93AE87658a9483b6',
    earnContractAddress: '0x2D4cf116A59e24Dd0aB8821c93AE87658a9483b6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-fff-quick',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['FFF', 'QUICK'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x9aceb6f749396d1930abc9e263efc449e5e82c13/0x831753dd7087cac61ab5644b308642cc1c33dc13',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0x9aceb6f749396d1930abc9e263efc449e5e82c13',
  },
  {
    id: 'aave-wbtc',
    logo: 'single-assets/BTCB.svg',
    name: 'WBTC',
    token: 'WBTC',
    tokenDescription: 'Aave',
    tokenAddress: '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
    tokenDecimals: 8,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAaveWBTC',
    earnedTokenAddress: '0xD3395577febc6AdaB25490a69955ebC47040766C',
    earnContractAddress: '0xD3395577febc6AdaB25490a69955ebC47040766C',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'WBTC',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Aave',
    assets: ['WBTC'],
    callFee: 0.5,
    withdrawalFee: '0.01%',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
  },
  {
    id: 'aave-eth',
    logo: 'single-assets/ETH.svg',
    name: 'ETH',
    token: 'ETH',
    tokenDescription: 'Aave',
    tokenAddress: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAaveETH',
    earnedTokenAddress: '0x77276a7c9Ff3a6cbD334524d6F1f6219D039ac0E',
    earnContractAddress: '0x77276a7c9Ff3a6cbD334524d6F1f6219D039ac0E',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'ETH',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Aave',
    assets: ['ETH'],
    callFee: 0.5,
    withdrawalFee: '0.01%',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
  },
  {
    id: 'quick-titan-eth-eol',
    logo: 'polygon/TITAN-ETH.png',
    name: 'TITAN-ETH LP',
    token: 'TITAN-ETH LP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xA28Ade2f27b9554b01964FDCe97eD643301411d9',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickTITAN-ETH',
    earnedTokenAddress: '0x1b27b7083a9BdA1aCD04266F61DF646f8296a872',
    earnContractAddress: '0x1b27b7083a9BdA1aCD04266F61DF646f8296a872',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-titan-eth',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['TITAN', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0xaAa5B9e6c589642f98a1cDA99B9D024B8407285A/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0xaAa5B9e6c589642f98a1cDA99B9D024B8407285A',
  },
  {
    id: 'iron-iron-usdc-quick-eol',
    logo: 'polygon/IRON-USDC.png',
    name: 'IRON-USDC LP',
    token: 'IRON-USDC QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x2Bbe0F728f4d5821F84eeE0432D2A4be7C0cB7Fc',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooIronQuickIRON-USDC',
    earnedTokenAddress: '0xe5D9d4F4C6369E24c8dA4cB07cCb2901845f6021',
    earnContractAddress: '0xe5D9d4F4C6369E24c8dA4cB07cCb2901845f6021',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'iron-iron-usdc-quick',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['IRON', 'USDC'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0xD86b5923F3AD7b585eD81B448170ae026c65ae9a/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0xD86b5923F3AD7b585eD81B448170ae026c65ae9a',
  },
  {
    id: 'polycat-sushi-fish-matic',
    logo: 'polygon/FISH-MATIC.svg',
    name: 'FISH-MATIC LP',
    token: 'FISH-MATIC SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0xcBF6f78981e63Ef813cb71852d72A060b583EECF',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPolycatSushiFISH-MATIC',
    earnedTokenAddress: '0xefA8E30cE4cc433cEA1B3b6006D69731A4FBd485',
    earnContractAddress: '0xefA8E30cE4cc433cEA1B3b6006D69731A4FBd485',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'polycat-sushi-fish-matic',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Polycat',
    assets: ['FISH', 'MATIC'],
    callFee: 0.05,
    addLiquidityUrl:
      'https://app.sushi.com/add/0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270/0x3a3df212b7aa91aa0402b9035b098891d276572b',
    buyTokenUrl:
      'https://app.sushi.com/swap?inputCurrency=0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270&outputCurrency=0x3a3df212b7aa91aa0402b9035b098891d276572b',
  },
  {
    id: 'polycat-quick-fish-matic',
    logo: 'polygon/FISH-MATIC.svg',
    name: 'FISH-MATIC LP',
    token: 'FISH-MATIC QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x289cf2B63c5Edeeeab89663639674d9233E8668E',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPolycatQuickFISH-MATIC',
    earnedTokenAddress: '0x7eE71053102d54Fc843BaEBaf07277C2b6dB64f1',
    earnContractAddress: '0x7eE71053102d54Fc843BaEBaf07277C2b6dB64f1',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'polycat-quick-fish-matic',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Polycat',
    assets: ['FISH', 'MATIC'],
    callFee: 0.05,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270/0x3a3Df212b7AA91Aa0402B9035b098891d276572B',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270&outputCurrency=0x3a3Df212b7AA91Aa0402B9035b098891d276572B',
  },
  {
    id: 'sushi-grt-eth',
    logo: 'polygon/GRT-ETH.png',
    name: 'GRT-ETH SLP',
    token: 'GRT-ETH SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0x1cedA73C034218255F50eF8a2c282E6B4c301d60',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiGRT-ETH',
    earnedTokenAddress: '0x8efCf419813F9E018cCaCda36151e5079c274fa4',
    earnContractAddress: '0x8efCf419813F9E018cCaCda36151e5079c274fa4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-grt-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['GRT', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://app.sushi.com/add/0x5fe2B58c013d7601147DcdD68C143A77499f5531/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0x5fe2B58c013d7601147DcdD68C143A77499f5531&outputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
  },
  {
    id: 'sushi-frax-fxs',
    logo: 'polygon/FRAX-FXS.png',
    name: 'FRAX-FXS SLP',
    token: 'FRAX-FXS SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0xd53a56AE0f48c9a03660cd36c2E4ae20493a1Eca',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiFRAX-FXS',
    earnedTokenAddress: '0xddD5F39d044Dbaeef7b348cf04C3628acd3F1D8f',
    earnContractAddress: '0xddD5F39d044Dbaeef7b348cf04C3628acd3F1D8f',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-frax-fxs',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['FRAX', 'FXS'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://app.sushi.com/add/0x104592a158490a9228070E0A8e5343B499e125D0/0x3e121107F6F22DA4911079845a470757aF4e1A1b',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0x104592a158490a9228070E0A8e5343B499e125D0&outputCurrency=0x3e121107F6F22DA4911079845a470757aF4e1A1b',
  },
  {
    id: 'sushi-frax-usdc',
    logo: 'polygon/FRAX-USDC.png',
    name: 'FRAX-USDC SLP',
    token: 'FRAX-USDC SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0x9e20a8d3501BF96EDA8e69b96DD84840058a1cB0',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiFRAX-USDC',
    earnedTokenAddress: '0x5B19A2e8E5878cF2b1E9b1AC7CEA50346671B2Fc',
    earnContractAddress: '0x5B19A2e8E5878cF2b1E9b1AC7CEA50346671B2Fc',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-frax-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['FRAX', 'USDC'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://app.sushi.com/add/0x104592a158490a9228070E0A8e5343B499e125D0/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0x104592a158490a9228070E0A8e5343B499e125D0&outputCurrency=0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
  },
  {
    id: 'sushi-wmatic-woofy',
    logo: 'polygon/MATIC-WOOFY.png',
    name: 'MATIC-WOOFY SLP',
    token: 'MATIC-WOOFY SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0x211F8e61113eDAf00cf37A804B0bA721875Ef560',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiMATIC-WOOFY',
    earnedTokenAddress: '0x544551E4E7D7bDd1CfbD55F07e304F5C9fD514Dd',
    earnContractAddress: '0x544551E4E7D7bDd1CfbD55F07e304F5C9fD514Dd',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-wmatic-woofy',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['MATIC', 'WOOFY'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://app.sushi.com/add/0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270/0xD0660cD418a64a1d44E9214ad8e459324D8157f1',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270&outputCurrency=0xD0660cD418a64a1d44E9214ad8e459324D8157f1',
  },
  {
    id: 'sushi-usdc-dai',
    logo: 'polygon/USDC-DAI.png',
    name: 'USDC-DAI SLP',
    token: 'USDC-DAI SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0xCD578F016888B57F1b1e3f887f392F0159E26747',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiUSDC-DAI',
    earnedTokenAddress: '0x75424BE5378621AeC2eEF25965f40FeB59039B52',
    earnContractAddress: '0x75424BE5378621AeC2eEF25965f40FeB59039B52',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-usdc-dai',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['USDC', 'DAI'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://app.sushi.com/add/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174&outputCurrency=0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
  },
  {
    id: 'sushi-snx-eth',
    logo: 'polygon/SNX-ETH.png',
    name: 'SNX-ETH SLP',
    token: 'SNX-ETH SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0x116Ff0d1Caa91a6b94276b3471f33dbeB52073E7',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiSNX-ETH',
    earnedTokenAddress: '0x3AD9cd8d75f7a711Caea58e725425A9dC0D249c4',
    earnContractAddress: '0x3AD9cd8d75f7a711Caea58e725425A9dC0D249c4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-snx-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['SNX', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://app.sushi.com/add/0x50B728D8D964fd00C2d0AAD81718b71311feF68a/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0x50B728D8D964fd00C2d0AAD81718b71311feF68a&outputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
  },
  {
    id: 'iron-titan-eol',
    logo: 'polygon/TITAN.png',
    name: 'TITAN',
    token: 'TITAN',
    tokenDescription: 'IronFinance',
    tokenAddress: '0xaAa5B9e6c589642f98a1cDA99B9D024B8407285A',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooIronTITAN',
    earnedTokenAddress: '0x8e3e85c7d4A616683f3F9468bC17169774cD1aFC',
    earnContractAddress: '0x8e3e85c7d4A616683f3F9468bC17169774cD1aFC',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'TITAN',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['TITAN'],
    callFee: 0.05,
    buyTokenUrl:
      'https://app.sushi.com/#/swap?outputCurrency=0xaAa5B9e6c589642f98a1cDA99B9D024B8407285A',
  },
  {
    id: 'iron-titan-iron-eol',
    logo: 'polygon/TITAN-IRON.png',
    name: 'TITAN-IRON LP',
    token: 'TITAN-IRON LP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0x35c1895DAC1e2432b320e2927b4F71a0D995602F',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooIronTITAN-IRON',
    earnedTokenAddress: '0x9525A39b7E642785c10F02287b6A181CE9552f44',
    earnContractAddress: '0x9525A39b7E642785c10F02287b6A181CE9552f44',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'iron-titan-iron',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['TITAN', 'IRON'],
    callFee: 0.05,
    addLiquidityUrl:
      'https://app.sushi.com/add/0xaAa5B9e6c589642f98a1cDA99B9D024B8407285A/0xD86b5923F3AD7b585eD81B448170ae026c65ae9a',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0xaAa5B9e6c589642f98a1cDA99B9D024B8407285A&outputCurrency=0xD86b5923F3AD7b585eD81B448170ae026c65ae9a',
  },
  {
    id: 'quick-bifi-eth',
    logo: 'polygon/BIFI-ETH.png',
    name: 'BIFI-ETH LP',
    token: 'BIFI-ETH QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x8b80417D92571720949fC22404200AB8FAf7775f',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickBIFI-ETH',
    earnedTokenAddress: '0x21bA98fCb000dFeD8eC3B94cB41BeA51A601A94c',
    earnContractAddress: '0x21bA98fCb000dFeD8eC3B94cB41BeA51A601A94c',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-bifi-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['BIFI', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0xfbdd194376de19a88118e84e279b977f165d01b8',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0xfbdd194376de19a88118e84e279b977f165d01b8',
  },
  {
    id: 'quick-bifi-quick',
    logo: 'polygon/BIFI-QUICK.png',
    name: 'BIFI-QUICK LP',
    token: 'BIFI-QUICK QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xC6a23BDd628c3B17fc0dF270e6BF8E48511950e2',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickBIFI-QUICK',
    earnedTokenAddress: '0xCC2755476B0573F0ee0D5a754Bb6fE720c3641Bb',
    earnContractAddress: '0xCC2755476B0573F0ee0D5a754Bb6fE720c3641Bb',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-bifi-quick',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['BIFI', 'QUICK'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x831753dd7087cac61ab5644b308642cc1c33dc13/0xfbdd194376de19a88118e84e279b977f165d01b8',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x831753dd7087cac61ab5644b308642cc1c33dc13&outputCurrency=0xfbdd194376de19a88118e84e279b977f165d01b8',
  },
  {
    id: 'quick-vision-quick-eol',
    logo: 'polygon/VISION-QUICK.png',
    name: 'VISION-QUICK LP',
    token: 'VISION-QUICK QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x52F31162e07C0158C5dDa8c922cA09B52881e471',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickVISION-QUICK',
    earnedTokenAddress: '0x9a3892d43363083fa8b557C3db8B8652E7061612',
    earnContractAddress: '0x9a3892d43363083fa8b557C3db8B8652E7061612',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-vision-quick',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'QuickSwap',
    assets: ['VISION', 'QUICK'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x831753dd7087cac61ab5644b308642cc1c33dc13/0x034b2090b579228482520c589dbd397c53fc51cc',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x831753dd7087cac61ab5644b308642cc1c33dc13&outputCurrency=0x034b2090b579228482520c589dbd397c53fc51cc',
  },
  {
    id: 'quick-degen-quick',
    logo: 'polygon/DEGEN-QUICK.png',
    name: 'DEGEN-QUICK LP',
    token: 'DEGEN-QUICK QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x75a4917aeE97BDA48D3572f431e04003743da85e',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickDEGEN-QUICK',
    earnedTokenAddress: '0xe942A8Ef245EAC3CEa951486e3Df5764C79b9621',
    earnContractAddress: '0xe942A8Ef245EAC3CEa951486e3Df5764C79b9621',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-degen-quick',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['DEGEN', 'QUICK'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x831753dd7087cac61ab5644b308642cc1c33dc13/0x8a2870fb69A90000D6439b7aDfB01d4bA383A415',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x831753dd7087cac61ab5644b308642cc1c33dc13&outputCurrency=0x8a2870fb69A90000D6439b7aDfB01d4bA383A415',
  },
  {
    id: 'quick-cc10-quick',
    logo: 'polygon/CC10-QUICK.png',
    name: 'CC10-QUICK LP',
    token: 'CC10-QUICK QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x42fD10ddc7628b82d80c3b2C286F3e79555Fd7A1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickCC10-QUICK',
    earnedTokenAddress: '0x226a18Fb5eb7d9d1c4Eb1b5Cff957E0F1e3fd9Ed',
    earnContractAddress: '0x226a18Fb5eb7d9d1c4Eb1b5Cff957E0F1e3fd9Ed',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-cc10-quick',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['CC10', 'QUICK'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x831753dd7087cac61ab5644b308642cc1c33dc13/0x9c49BA0212Bb5Db371e66b59D1565b7c06E4894e',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x831753dd7087cac61ab5644b308642cc1c33dc13&outputCurrency=0x9c49BA0212Bb5Db371e66b59D1565b7c06E4894e',
  },
  {
    id: 'quick-defi5-quick',
    logo: 'polygon/DEFI5-QUICK.png',
    name: 'DEFI5-QUICK LP',
    token: 'DEFI5-QUICK QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xa975CEEb05B379Cf67FdfA63e7b2770f9F1D72c6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickDEFI5-QUICK',
    earnedTokenAddress: '0x191E0b3B023fd8911c1D7632086A46C0D2dB39ed',
    earnContractAddress: '0x191E0b3B023fd8911c1D7632086A46C0D2dB39ed',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-defi5-quick',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['DEFI5', 'QUICK'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x831753dd7087cac61ab5644b308642cc1c33dc13/0x42435F467D33e5C4146a4E8893976ef12BBCE762',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x831753dd7087cac61ab5644b308642cc1c33dc13&outputCurrency=0x42435F467D33e5C4146a4E8893976ef12BBCE762',
  },
  {
    id: 'quick-btc-usdc',
    logo: 'polygon/BTC-USDC.png',
    name: 'WBTC-USDC LP',
    token: 'WBTC-USDC QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xF6a637525402643B0654a54bEAd2Cb9A83C8B498',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickwBTC-USDC',
    earnedTokenAddress: '0x91a55E4b057119e20334258f7C5EAB8822491CEb',
    earnContractAddress: '0x91a55E4b057119e20334258f7C5EAB8822491CEb',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-btc-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['WBTC', 'USDC'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6&outputCurrency=0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
  },
  {
    id: 'iron-titan-matic-eol',
    logo: 'polygon/TITAN-MATIC.png',
    name: 'TITAN-MATIC LP',
    token: 'TITAN-MATIC LP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0xA79983Daf2A92c2C902cD74217Efe3D8AF9Fba2a',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooIronTITAN-MATIC',
    earnedTokenAddress: '0xE63aCEbE35265896cC6E8BdB8eCC0640a1807141',
    earnContractAddress: '0xE63aCEbE35265896cC6E8BdB8eCC0640a1807141',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'iron-titan-matic',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['TITAN', 'MATIC'],
    callFee: 0.5,
    addLiquidityUrl: 'https://app.sushi.com/add/ETH/0xaAa5B9e6c589642f98a1cDA99B9D024B8407285A',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?outputCurrency=0xaAa5B9e6c589642f98a1cDA99B9D024B8407285A',
  },
  {
    id: 'iron-iron-usdc-eol',
    logo: 'polygon/IRON-USDC.png',
    name: 'IRON-USDC LP',
    token: 'IRON-USDC LP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0x85dE135fF062Df790A5f20B79120f17D3da63b2d',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooIronIRON-USDC',
    earnedTokenAddress: '0x3e349b83A3E68bdD5BB71fAB63dDE123c478FEA4',
    earnContractAddress: '0x3e349b83A3E68bdD5BB71fAB63dDE123c478FEA4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'iron-iron-usdc',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['IRON', 'USDC'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://app.sushi.com/add/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/0xD86b5923F3AD7b585eD81B448170ae026c65ae9a',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?outputCurrency=0xD86b5923F3AD7b585eD81B448170ae026c65ae9a',
  },
  {
    id: 'quick-matic-usdc',
    logo: 'polygon/MATIC-USDC.png',
    name: 'MATIC-USDC LP',
    token: 'MATIC-USDC QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x6e7a5FAFcec6BB1e78bAE2A1F0B612012BF14827',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickMATIC-USDC',
    earnedTokenAddress: '0xC1A2e8274D390b67A7136708203D71BF3877f158',
    earnContractAddress: '0xC1A2e8274D390b67A7136708203D71BF3877f158',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-matic-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['MATIC', 'USDC'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/ETH/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=ETH&outputCurrency=0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
  },
  {
    id: 'quick-vision-eth',
    logo: 'polygon/VISION-ETH.png',
    name: 'VISION-ETH LP',
    token: 'VISION-ETH QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x47Be4b1b6921a36591142E108B8C9E04BB55E015',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickVISION-ETH',
    earnedTokenAddress: '0xAbA81D550C326DFf2cE0D31bC7Aa6289d576591E',
    earnContractAddress: '0xAbA81D550C326DFf2cE0D31bC7Aa6289d576591E',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-vision-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['VISION', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0x034b2090b579228482520c589dbd397c53fc51cc',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0x034b2090b579228482520c589dbd397c53fc51cc',
  },
  {
    id: 'curve-am3crv',
    logo: 'polygon/CURVE-3Pool.png',
    name: 'DAI/USDC/USDT',
    token: 'am3CRV',
    tokenDescription: 'Curve',
    tokenAddress: '0xE7a24EF0C5e95Ffb0f6684b813A78F2a3AD7D171',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCurveAm3CRV',
    earnedTokenAddress: '0xAA7C2879DaF8034722A0977f13c343aF0883E92e',
    earnContractAddress: '0xAA7C2879DaF8034722A0977f13c343aF0883E92e',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'curve-am3crv',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Curve',
    assets: ['DAI', 'USDC', 'USDT'],
    callFee: 0.5,
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://polygon.curve.fi/aave/deposit',
  },
  {
    id: 'quick-degen-eth',
    logo: 'polygon/DEGEN-ETH.png',
    name: 'DEGEN-ETH LP',
    token: 'DEGEN-ETH QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xFdBDb3A2bbdC7d9dC6203DcEf9D53f1735135951',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickDEGEN-ETH',
    earnedTokenAddress: '0xF7A5eC9168B4C5688b3ad599Aa5c8E1922fEacAE',
    earnContractAddress: '0xF7A5eC9168B4C5688b3ad599Aa5c8E1922fEacAE',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-degen-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['DEGEN', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0x8a2870fb69A90000D6439b7aDfB01d4bA383A415',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0x8a2870fb69A90000D6439b7aDfB01d4bA383A415',
  },
  {
    id: 'quick-cc10-eth',
    logo: 'polygon/CC10-ETH.png',
    name: 'CC10-ETH LP',
    token: 'CC10-ETH QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xBF646010B1ae86aD7fBb4cFf5fd93C7019331Cc9',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickCC10-ETH',
    earnedTokenAddress: '0x6F6bbbCA49b4b2cE0E27eb0156977048AC3434B9',
    earnContractAddress: '0x6F6bbbCA49b4b2cE0E27eb0156977048AC3434B9',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-cc10-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['CC10', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0x9c49BA0212Bb5Db371e66b59D1565b7c06E4894e',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0x9c49BA0212Bb5Db371e66b59D1565b7c06E4894e',
  },
  {
    id: 'adamant-addy-eol',
    logo: 'polygon/ADDY.png',
    name: 'ADDY',
    token: 'ADDY',
    tokenDescription: 'Adamant',
    tokenAddress: '0xc3FdbadC7c795EF1D6Ba111e06fF8F16A20Ea539',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAdamantADDY',
    earnedTokenAddress: '0x6b7c061949BE60d59F857fcc08b76c278c3B73C0',
    earnContractAddress: '0x6b7c061949BE60d59F857fcc08b76c278c3B73C0',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'ADDY',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['ADDY'],
    callFee: 0.5,
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0xc3fdbadc7c795ef1d6ba111e06ff8f16a20ea539',
  },
  {
    id: 'pzap-pzap-usdc',
    logo: 'polygon/PZAP-USDC.png',
    name: 'PZAP-USDC LP',
    token: 'PZAP-USDC LP',
    tokenDescription: 'Polyzap',
    tokenAddress: '0x1df7ADAeD1F7f36C822A3079198815b284aD8475',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPzapPZAP-USDC',
    earnedTokenAddress: '0xBA4FA9A5e1e573fA5267970238af5Edf40727315',
    earnContractAddress: '0xBA4FA9A5e1e573fA5267970238af5Edf40727315',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'pzap-pzap-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Polyzap',
    assets: ['PZAP', 'USDC'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://polyzap.app/#/add/0x2791bca1f2de4661ed88a30c99a7a9449aa84174/0xeb2778f74E5ee038E67AA6c77f0F0451ABd748FD',
    buyTokenUrl:
      'https://polyzap.app/#/swap?outputCurrency=0xeb2778f74E5ee038E67AA6c77f0F0451ABd748FD',
  },
  {
    id: 'pzap-pzap-matic',
    logo: 'polygon/PZAP-MATIC.png',
    name: 'PZAP-MATIC LP',
    token: 'PZAP-MATIC LP',
    tokenDescription: 'Polyzap',
    tokenAddress: '0xf627E14c318045066dAe0A36997c8C1F34fC0D4c',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPzapPZAP-MATIC',
    earnedTokenAddress: '0xf2984c27B963A14F9f3B7326096c54fb05d5b9AF',
    earnContractAddress: '0xf2984c27B963A14F9f3B7326096c54fb05d5b9AF',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'pzap-pzap-matic',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Polyzap',
    assets: ['PZAP', 'MATIC'],
    callFee: 0.5,
    addLiquidityUrl: 'https://polyzap.app/#/add/ETH/0xeb2778f74E5ee038E67AA6c77f0F0451ABd748FD',
    buyTokenUrl:
      'https://polyzap.app/#/swap?outputCurrency=0xeb2778f74E5ee038E67AA6c77f0F0451ABd748FD',
  },
  {
    id: 'cometh-bifi-eth',
    logo: 'polygon/BIFI-ETH.png',
    name: 'BIFI-ETH LP',
    token: 'BIFI-ETH LP',
    tokenDescription: 'Cometh',
    tokenAddress: '0xee3987aa1B20944EF4f8f049Cf1763007C06cFBC',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooComethBIFI-ETH',
    earnedTokenAddress: '0x9649e6E5c689f21BC27B47CE4177f7c5f7281E20',
    earnContractAddress: '0x9649e6E5c689f21BC27B47CE4177f7c5f7281E20',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cometh-bifi-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Cometh',
    assets: ['BIFI', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://swap.cometh.io/#/add/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619/0xfbdd194376de19a88118e84e279b977f165d01b8',
    buyTokenUrl:
      'https://swap.cometh.io/#/swap?inputCurrency=0x7ceb23fd6bc0add59e62ac25578270cff1b9f619&outputCurrency=0xfbdd194376de19a88118e84e279b977f165d01b8',
  },
  {
    id: 'cometh-bifi-must',
    logo: 'polygon/BIFI-MUST.png',
    name: 'BIFI-MUST LP',
    token: 'BIFI-MUST LP',
    tokenDescription: 'Cometh',
    tokenAddress: '0x9292aa90F81dc77fE6e6BB34b276502b6a047F7d',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooComethBIFI-MUST',
    earnedTokenAddress: '0x66b3d910c30f2305EA0078E06DF65e0c1745ceF0',
    earnContractAddress: '0x66b3d910c30f2305EA0078E06DF65e0c1745ceF0',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cometh-bifi-must',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Cometh',
    assets: ['BIFI', 'MUST'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://swap.cometh.io/#/add/0x9c78ee466d6cb57a4d01fd887d2b5dfb2d46288f/0xfbdd194376de19a88118e84e279b977f165d01b8',
    buyTokenUrl:
      'https://swap.cometh.io/#/swap?inputCurrency=0x9c78ee466d6cb57a4d01fd887d2b5dfb2d46288f&outputCurrency=0xfbdd194376de19a88118e84e279b977f165d01b8',
  },
  {
    id: 'aave-matic',
    logo: 'single-assets/MATIC.svg',
    name: 'MATIC',
    token: 'MATIC',
    tokenDescription: 'Aave',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAaveMATIC',
    earnedTokenAddress: '0x1d23ecC0645B07791b7D99349e253ECEbe43f614',
    earnContractAddress: '0x1d23ecC0645B07791b7D99349e253ECEbe43f614',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'WMATIC',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Aave',
    assets: ['MATIC'],
    callFee: 0.5,
    withdrawalFee: '0.01%',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
  },
  {
    id: 'aave-aave',
    logo: 'single-assets/AAVE.svg',
    name: 'AAVE',
    token: 'AAVE',
    tokenDescription: 'Aave',
    tokenAddress: '0xD6DF932A45C0f255f85145f286eA0b292B21C90B',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAaveAAVE',
    earnedTokenAddress: '0xe4b3CCbB1E48c579Ea3D764Fb258Bc908e46487E',
    earnContractAddress: '0xe4b3CCbB1E48c579Ea3D764Fb258Bc908e46487E',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'AAVE',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Aave',
    assets: ['AAVE'],
    callFee: 0.5,
    withdrawalFee: '0.01%',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0xD6DF932A45C0f255f85145f286eA0b292B21C90B',
  },
  {
    id: 'aave-dai',
    logo: 'single-assets/DAI.svg',
    name: 'DAI',
    token: 'DAI',
    tokenDescription: 'Aave',
    tokenAddress: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAaveDAI',
    earnedTokenAddress: '0x9B36ECeaC46B70ACfB7C2D6F3FD51aEa87C31018',
    earnContractAddress: '0x9B36ECeaC46B70ACfB7C2D6F3FD51aEa87C31018',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'DAI',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Aave',
    assets: ['DAI'],
    callFee: 0.5,
    withdrawalFee: '0.01%',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
  },
  {
    id: 'aave-usdc',
    logo: 'single-assets/USDC.svg',
    name: 'USDC',
    token: 'USDC',
    tokenDescription: 'Aave',
    tokenAddress: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    tokenDecimals: 6,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAaveUSDC',
    earnedTokenAddress: '0xE71f3C11D4535a7F8c5FB03FDA57899B2C9c721F',
    earnContractAddress: '0xE71f3C11D4535a7F8c5FB03FDA57899B2C9c721F',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'USDC',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Aave',
    assets: ['USDC'],
    callFee: 0.5,
    withdrawalFee: '0.01%',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
  },
  {
    id: 'sushi-crv-eth',
    logo: 'polygon/CRV-ETH.png',
    name: 'CRV-ETH LP',
    token: 'CRV-ETH SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0x396E655C309676cAF0acf4607a868e0CDed876dB',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiCRV-ETH',
    earnedTokenAddress: '0xE695fCeD8fD93eeE54204a7fC33323a60d41865A',
    earnContractAddress: '0xE695fCeD8fD93eeE54204a7fC33323a60d41865A',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-crv-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['CRV', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://app.sushi.com/#/add/0x172370d5cd63279efa6d502dab29171933a610af/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0x172370d5cd63279efa6d502dab29171933a610af&outputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
  },
  {
    id: 'sushi-usdc-usdt',
    logo: 'polygon/USDC-USDT.png',
    name: 'USDC-USDT LP',
    token: 'USDC-USDT SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0x4B1F1e2435A9C96f7330FAea190Ef6A7C8D70001',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiUSDC-USDT',
    earnedTokenAddress: '0xB6B89a05ad8228b98d0D8a77e1a695c54500db3b',
    earnContractAddress: '0xB6B89a05ad8228b98d0D8a77e1a695c54500db3b',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-usdc-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['USDC', 'USDT'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://app.sushi.com/#/add/0x2791bca1f2de4661ed88a30c99a7a9449aa84174/0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0x2791bca1f2de4661ed88a30c99a7a9449aa84174&outputCurrency=0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
  },
  {
    id: 'sushi-link-eth',
    logo: 'polygon/LINK-ETH.png',
    name: 'LINK-ETH LP',
    token: 'LINK-ETH SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0x74D23F21F780CA26B47Db16B0504F2e3832b9321',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiLINK-ETH',
    earnedTokenAddress: '0xaE65a66B3c7f8cc1ba71cEA740C953aBa0F3Ce8b',
    earnContractAddress: '0xaE65a66B3c7f8cc1ba71cEA740C953aBa0F3Ce8b',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-link-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['LINK', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://app.sushi.com/#/add/0x53e0bca35ec356bd5dddfebbd1fc0fd03fabad39/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0x53e0bca35ec356bd5dddfebbd1fc0fd03fabad39&outputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
  },
  {
    id: 'quick-mocean-matic',
    logo: 'polygon/mOCEAN-MATIC.png',
    name: 'mOCEAN-MATIC LP',
    token: 'mOCEAN-MATIC QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x5a94F81D25c73eDdBDD84b84E8F6D36C58270510',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickmOCEAN-MATIC',
    earnedTokenAddress: '0x82303a4b2c821204A84AB2a068eC8EDf3Bc23061',
    earnContractAddress: '0x82303a4b2c821204A84AB2a068eC8EDf3Bc23061',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-mocean-matic',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['mOCEAN', 'MATIC'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270/0x282d8efce846a88b159800bd4130ad77443fa1a1',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6',
  },
  {
    id: 'quick-any-quick',
    logo: 'polygon/ANY-QUICK.png',
    name: 'ANY-QUICK LP',
    token: 'ANY-QUICK QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xeb275d1d930F157504cca7D7AFCe38360C7302b5',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickANY-QUICK',
    earnedTokenAddress: '0x2849095eE44eCd5f60Ed04f94e5BB45623A8e75a',
    earnContractAddress: '0x2849095eE44eCd5f60Ed04f94e5BB45623A8e75a',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-any-quick',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['ANY', 'QUICK'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x6ab6d61428fde76768d7b45d8bfeec19c6ef91a8/0x831753dd7087cac61ab5644b308642cc1c33dc13',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x6ab6d61428fde76768d7b45d8bfeec19c6ef91a8&outputCurrency=0x831753dd7087cac61ab5644b308642cc1c33dc13',
  },
  {
    id: 'quick-frax-quick',
    logo: 'polygon/FRAX-QUICK.png',
    name: 'FRAX-QUICK LP',
    token: 'FRAX-QUICK QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x2Aa7a18cEAbf2eF893D2f7c0145cC45e6f10b223',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickFRAX-QUICK',
    earnedTokenAddress: '0x442ca31De7E6732455e60e3641Ac076fa7a0905f',
    earnContractAddress: '0x442ca31De7E6732455e60e3641Ac076fa7a0905f',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-frax-quick',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['FRAX', 'QUICK'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x104592a158490a9228070e0a8e5343b499e125d0/0x831753dd7087cac61ab5644b308642cc1c33dc13',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x104592a158490a9228070e0a8e5343b499e125d0&outputCurrency=0x831753dd7087cac61ab5644b308642cc1c33dc13',
  },
  {
    id: 'sushi-aave-eth',
    logo: 'polygon/AAVE-ETH.png',
    name: 'AAVE-ETH LP',
    token: 'AAVE-ETH SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0x2813D43463C374a680f235c428FB1D7f08dE0B69',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiAAVE-ETH',
    earnedTokenAddress: '0x866a910F3375d0dEBDdD904A36B3940aFcD29900',
    earnContractAddress: '0x866a910F3375d0dEBDdD904A36B3940aFcD29900',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-aave-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['AAVE', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://app.sushi.com/#/add/0xD6DF932A45C0f255f85145f286eA0b292B21C90B/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0xD6DF932A45C0f255f85145f286eA0b292B21C90B&outputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
  },
  {
    id: 'sushi-eth-dai',
    logo: 'polygon/ETH-DAI.png',
    name: 'ETH-DAI LP',
    token: 'ETH-DAI SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0x6FF62bfb8c12109E8000935A6De54daD83a4f39f',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiETH-DAI',
    earnedTokenAddress: '0x6b6ca47520dDC9333B8bD09a1e64204648B63274',
    earnContractAddress: '0x6b6ca47520dDC9333B8bD09a1e64204648B63274',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-eth-dai',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['ETH', 'DAI'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://app.sushi.com/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
  },
  {
    id: 'sushi-btc-eth',
    logo: 'polygon/ETH-BTC.png',
    name: 'WBTC-ETH LP',
    token: 'WBTC-ETH SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0xE62Ec2e799305E0D367b0Cc3ee2CdA135bF89816',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiBTC-ETH',
    earnedTokenAddress: '0x6530E351074f1f9fdf254dC7d7d8A44324E158a4',
    earnContractAddress: '0x6530E351074f1f9fdf254dC7d7d8A44324E158a4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-btc-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['WBTC', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://app.sushi.com/#/add/0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6&outputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
  },
  {
    id: 'sushi-eth-usdt',
    logo: 'polygon/ETH-USDT.png',
    name: 'ETH-USDT LP',
    token: 'ETH-USDT SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0xc2755915a85C6f6c1C0F3a86ac8C058F11Caa9C9',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiETH-USDT',
    earnedTokenAddress: '0xE8417099971151CD5CfAE264e25634Fac05cA2b3',
    earnContractAddress: '0xE8417099971151CD5CfAE264e25634Fac05cA2b3',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-eth-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['ETH', 'USDT'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://app.sushi.com/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
  },
  {
    id: 'sushi-usdc-eth',
    logo: 'polygon/USDC-ETH.png',
    name: 'USDC-ETH LP',
    token: 'USDC-ETH SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0x34965ba0ac2451A34a0471F04CCa3F990b8dea27',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiUSDC-ETH',
    earnedTokenAddress: '0xE4DB97A2AAFbfef40D1a4AE8B709f61d6756F8e1',
    earnContractAddress: '0xE4DB97A2AAFbfef40D1a4AE8B709f61d6756F8e1',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-usdc-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['USDC', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://app.sushi.com/#/add/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174&outputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
  },
  {
    id: 'sushi-matic-eth',
    logo: 'polygon/ETH-MATIC.png',
    name: 'ETH-MATIC LP',
    token: 'MATIC-ETH SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0xc4e595acDD7d12feC385E5dA5D43160e8A0bAC0E',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiMATIC-ETH',
    earnedTokenAddress: '0xC8e809a9180d637Cc23dAf60b41B70CA1ad5Fc08',
    earnContractAddress: '0xC8e809a9180d637Cc23dAf60b41B70CA1ad5Fc08',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-matic-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['MATIC', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://app.sushi.com/#/add/0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270&outputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
  },
  {
    id: 'cometh-azuki-eth',
    logo: 'polygon/AZUKI-ETH.png',
    name: 'AZUKI-ETH LP',
    token: 'AZUKI-ETH LP',
    tokenDescription: 'Cometh',
    tokenAddress: '0x92Bb3233F59561FC1fEC53EfC3339E4Af8E917F4',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooComethAZUKI-ETH',
    earnedTokenAddress: '0xe95d14D09a8F6034C582bd993A2F2aA8ecEC72f0',
    earnContractAddress: '0xe95d14D09a8F6034C582bd993A2F2aA8ecEC72f0',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cometh-azuki-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Cometh',
    assets: ['AZUKI', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://swap.cometh.io/#/add/0x7CdC0421469398e0F3aA8890693d86c840Ac8931/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
    buyTokenUrl:
      'https://swap.cometh.io/#/swap?inputCurrency=0x7ceb23fd6bc0add59e62ac25578270cff1b9f619&outputCurrency=0x7CdC0421469398e0F3aA8890693d86c840Ac8931',
  },
  {
    id: 'cometh-doki-eth',
    logo: 'polygon/DOKI-ETH.png',
    name: 'DOKI-ETH LP',
    token: 'DOKI-ETH LP',
    tokenDescription: 'Cometh',
    tokenAddress: '0xcCeD5cB001D6081c4561bf7911F11Ccd9aAA1474',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooComethDOKI-ETH',
    earnedTokenAddress: '0x383489a0A5deA3f1499c638e0258F7e6a68a253f',
    earnContractAddress: '0x383489a0A5deA3f1499c638e0258F7e6a68a253f',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cometh-doki-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Cometh',
    assets: ['DOKI', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://swap.cometh.io/#/add/0x5C7F7Fe4766fE8f0fa9b41E2E4194d939488ff1C/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
    buyTokenUrl:
      'https://swap.cometh.io/#/swap?inputCurrency=0x7ceb23fd6bc0add59e62ac25578270cff1b9f619&outputCurrency=0x5C7F7Fe4766fE8f0fa9b41E2E4194d939488ff1C',
  },
  {
    id: 'quick-usdc-usdt',
    logo: 'polygon/USDC-USDT.png',
    name: 'USDC-USDT LP',
    token: 'USDC-USDT QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x2cF7252e74036d1Da831d11089D326296e64a728',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickUSDC-USDT',
    earnedTokenAddress: '0x4462817b53E76b722c2D174D0148ddb81452f1dE',
    earnContractAddress: '0x4462817b53E76b722c2D174D0148ddb81452f1dE',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-usdc-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['USDC', 'USDT'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x2791bca1f2de4661ed88a30c99a7a9449aa84174&outputCurrency=0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
  },
  {
    id: 'cometh-must-eth',
    logo: 'polygon/MUST-ETH.png',
    name: 'MUST-ETH LP',
    token: 'MUST-ETH LP',
    tokenDescription: 'Cometh',
    tokenAddress: '0x8826C072657983939c26E684edcfb0e4133f0B3d',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooComethMUST-ETH',
    earnedTokenAddress: '0x7CE2332fAF6328986C75e3A8fCc1CB79621aeB1F',
    earnContractAddress: '0x7CE2332fAF6328986C75e3A8fCc1CB79621aeB1F',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cometh-must-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Cometh',
    assets: ['MUST', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://swap.cometh.io/#/add/0x9C78EE466D6Cb57A4d01Fd887D2b5dFb2D46288f/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
    buyTokenUrl:
      'https://swap.cometh.io/#/swap?inputCurrency=0x7ceb23fd6bc0add59e62ac25578270cff1b9f619&outputCurrency=0x9c78ee466d6cb57a4d01fd887d2b5dfb2d46288f',
  },
  {
    id: 'quick-eth-btc',
    logo: 'polygon/ETH-BTC.png',
    name: 'WBTC-ETH LP',
    token: 'WBTC-ETH QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xdC9232E2Df177d7a12FdFf6EcBAb114E2231198D',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickwBTC-ETH',
    earnedTokenAddress: '0xf26607237355D7c6183ea66EC908729E9c6eEB6b',
    earnContractAddress: '0xf26607237355D7c6183ea66EC908729E9c6eEB6b',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-eth-btc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['WBTC', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6',
  },
  {
    id: 'quick-ubt-eth',
    logo: 'polygon/UBT-ETH.png',
    name: 'UBT-ETH LP',
    token: 'UBT-ETH QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xcc203f45A31aE086218170F6A9e9623fA1655486',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickUBT-ETH',
    earnedTokenAddress: '0x942aa6324E5D0C102d3Ad6607495ac5e798a991a',
    earnContractAddress: '0x942aa6324E5D0C102d3Ad6607495ac5e798a991a',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-ubt-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['UBT', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0x7FBc10850caE055B27039aF31bD258430e714c62',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0x7FBc10850caE055B27039aF31bD258430e714c62',
  },
  {
    id: 'quick-defi5-eth',
    logo: 'polygon/DEFI5-ETH.png',
    name: 'DEFI5-ETH LP',
    token: 'DEFI5-ETH QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x654E651b658f784406125400cf648588CB9773e8',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickDEFI5-ETH',
    earnedTokenAddress: '0x53e674D5cC969930420d73E4b79Ee0D46cCdf6c4',
    earnContractAddress: '0x53e674D5cC969930420d73E4b79Ee0D46cCdf6c4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-defi5-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['DEFI5', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0x42435F467D33e5C4146a4E8893976ef12BBCE762',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0x42435F467D33e5C4146a4E8893976ef12BBCE762',
  },
  {
    id: 'quick-wise-eth',
    logo: 'polygon/WISE-ETH.png',
    name: 'WISE-ETH LP',
    token: 'WISE-ETH QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xDF8139e9BebecAdEcF48BeC8c8064CcEfB618e2b',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickWISE-ETH',
    earnedTokenAddress: '0x76cE86B1e1b7a3983B26F7E57B2A0C8896f7eB0D',
    earnContractAddress: '0x76cE86B1e1b7a3983B26F7E57B2A0C8896f7eB0D',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-wise-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['WISE', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0xB77e62709e39aD1cbeEBE77cF493745AeC0F453a',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0xB77e62709e39aD1cbeEBE77cF493745AeC0F453a',
  },
  {
    id: 'quick-cel-eth',
    logo: 'polygon/CEL-ETH.png',
    name: 'CEL-ETH LP',
    token: 'CEL-ETH QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xEAA5E4620373d9Ded4DCB9267F46fcfc6698c867',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickCEL-ETH',
    earnedTokenAddress: '0xeF1870FddC25586636bf8a3d7cCf4d298f6E072e',
    earnContractAddress: '0xeF1870FddC25586636bf8a3d7cCf4d298f6E072e',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-cel-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['CEL', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0xD85d1e945766Fea5Eda9103F918Bd915FbCa63E6',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0xD85d1e945766Fea5Eda9103F918Bd915FbCa63E6',
  },
  {
    id: 'quick-quick-eth',
    logo: 'polygon/QUICK-ETH.png',
    name: 'QUICK-ETH LP',
    token: 'QUICK-ETH QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x1Bd06B96dd42AdA85fDd0795f3B4A79DB914ADD5',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickQUICK-ETH',
    earnedTokenAddress: '0x66df1B2d22759D03A9f37BAaAc826089e56a5936',
    earnContractAddress: '0x66df1B2d22759D03A9f37BAaAc826089e56a5936',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-quick-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['QUICK', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0x831753dd7087cac61ab5644b308642cc1c33dc13',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0x831753dd7087cac61ab5644b308642cc1c33dc13',
  },
  {
    id: 'quick-mausdc-usdc-eol',
    logo: 'polygon/maUSDC-USDC.png',
    name: 'maUSDC-USDC LP',
    token: 'maUSDC-USDC QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x7295304b10740BA8e037826787d3e9386FD99925',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickmaUSDC-USDC',
    earnedTokenAddress: '0x8c2d54BA94f4638f1bb91f623F378B66d6023324',
    earnContractAddress: '0x8c2d54BA94f4638f1bb91f623F378B66d6023324',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-mausdc-usdc',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'QuickSwap',
    assets: ['maUSDC', 'USDC'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x9719d867A500Ef117cC201206B8ab51e794d3F82/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x2791bca1f2de4661ed88a30c99a7a9449aa84174&outputCurrency=0x9719d867a500ef117cc201206b8ab51e794d3f82',
  },
  {
    id: 'quick-aave-eth',
    logo: 'polygon/AAVE-ETH.png',
    name: 'AAVE-ETH LP',
    token: 'AAVE-ETH QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x90bc3E68Ba8393a3Bf2D79309365089975341a43',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickAAVE-ETH',
    earnedTokenAddress: '0x752948B4493d2Ee09c95F944A76005aEBF410087',
    earnContractAddress: '0x752948B4493d2Ee09c95F944A76005aEBF410087',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-aave-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['AAVE', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0xD6DF932A45C0f255f85145f286eA0b292B21C90B/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0xD6DF932A45C0f255f85145f286eA0b292B21C90B&outputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
  },
  {
    id: 'quick-link-eth',
    logo: 'polygon/LINK-ETH.png',
    name: 'LINK-ETH LP',
    token: 'LINK-ETH QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x5cA6CA6c3709E1E6CFe74a50Cf6B2B6BA2Dadd67',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickLINK-ETH',
    earnedTokenAddress: '0xaB4105375FbE5F502B0da986F46ADf7a21762e52',
    earnContractAddress: '0xaB4105375FbE5F502B0da986F46ADf7a21762e52',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-link-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['LINK', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x53e0bca35ec356bd5dddfebbd1fc0fd03fabad39/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x53e0bca35ec356bd5dddfebbd1fc0fd03fabad39&outputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
  },
  {
    id: 'krill-krill-usdc-eol',
    logo: 'polygon/KRILL-USDC.png',
    name: 'KRILL-USDC LP',
    token: 'KRILL-USDC LP',
    tokenDescription: 'Polywhale',
    tokenAddress: '0x6405Ebc22cB0899FC21f414085Ac4044B4721a0d',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPolywhaleKRILL-USDC',
    earnedTokenAddress: '0x74907ad4E79b1Ce415caB26FEf526ae017598cEe',
    earnContractAddress: '0x74907ad4E79b1Ce415caB26FEf526ae017598cEe',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'krill-krill-usdc',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['KRILL', 'USDC'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/0x05089C9EBFFa4F0AcA269e32056b1b36B37ED71b',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x05089c9ebffa4f0aca269e32056b1b36b37ed71b&outputCurrency=0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
  },
  {
    id: 'quick-eth-usdt',
    logo: 'polygon/ETH-USDT.png',
    name: 'ETH-USDT LP',
    token: 'ETH-USDT QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xF6422B997c7F54D1c6a6e103bcb1499EeA0a7046',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickETH-USDT',
    earnedTokenAddress: '0xC422261EdC5dB679CAd9BC403e886351De540e77',
    earnContractAddress: '0xC422261EdC5dB679CAd9BC403e886351De540e77',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-eth-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['ETH', 'USDT'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/ETH/0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=ETH&outputCurrency=0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
  },
  {
    id: 'quick-eth-matic',
    //logo: 'polygon/ETH-MATIC.png',
    name: 'ETH-MATIC LP',
    token: 'ETH-MATIC QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xadbF1854e5883eB8aa7BAf50705338739e558E5b',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickETH-MATIC',
    earnedTokenAddress: '0x8b89477dFde285849E1B07947E25012206F4D674',
    earnContractAddress: '0x8b89477dFde285849E1B07947E25012206F4D674',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-eth-matic',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['ETH', 'MATIC'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/ETH/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270&outputCurrency=ETH',
  },
  {
    id: 'quick-quick-matic',
    logo: 'polygon/QUICK-MATIC.png',
    name: 'QUICK-MATIC LP',
    token: 'QUICK-MATIC QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x019ba0325f1988213D448b3472fA1cf8D07618d7',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickQUICK-MATIC',
    earnedTokenAddress: '0xa008B727ddBa283Ddb178b47BB227Cdbea5C1bfD',
    earnContractAddress: '0xa008B727ddBa283Ddb178b47BB227Cdbea5C1bfD',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-quick-matic',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['QUICK', 'MATIC'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270/0x831753dd7087cac61ab5644b308642cc1c33dc13',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270&outputCurrency=0x831753dd7087cac61ab5644b308642cc1c33dc13',
  },
  {
    id: 'cometh-eth-matic',
    logo: 'polygon/ETH-MATIC.png',
    name: 'ETH-MATIC LP',
    token: 'ETH-MATIC LP',
    tokenDescription: 'Cometh',
    tokenAddress: '0x462390EA53F70F7570EEB2002c0150aF519f93A9',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooComethETH-MATIC',
    earnedTokenAddress: '0xa5aaE3a55cA356C62b5425AA4bFC212542B17777',
    earnContractAddress: '0xa5aaE3a55cA356C62b5425AA4bFC212542B17777',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cometh-eth-matic',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Cometh',
    assets: ['ETH', 'MATIC'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://swap.cometh.io/#/add/0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
    buyTokenUrl:
      'https://swap.cometh.io/#/swap?inputCurrency=0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270&outputCurrency=0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
  },
  {
    id: 'cometh-matic-must',
    logo: 'polygon/MUST-MATIC.png',
    name: 'MUST-MATIC LP',
    token: 'MUST-MATIC LP',
    tokenDescription: 'Cometh',
    tokenAddress: '0x80676b414a905De269D0ac593322Af821b683B92',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooComethMUST-MATIC',
    earnedTokenAddress: '0x7f6fE34C51d5352A0CF375C0Fbe03bD19eCD8460',
    earnContractAddress: '0x7f6fE34C51d5352A0CF375C0Fbe03bD19eCD8460',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cometh-matic-must',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Cometh',
    assets: ['MUST', 'MATIC'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://swap.cometh.io/#/add/0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270/0x9c78ee466d6cb57a4d01fd887d2b5dfb2d46288f',
    buyTokenUrl:
      'https://swap.cometh.io/#/swap?inputCurrency=0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270&outputCurrency=0x9c78ee466d6cb57a4d01fd887d2b5dfb2d46288f',
  },
  {
    id: 'cometh-usdc-must',
    logo: 'polygon/USDC-MUST.png',
    name: 'USDC-MUST LP',
    token: 'USDC-MUST LP',
    tokenDescription: 'Cometh',
    tokenAddress: '0x12A2aBcFcADc04681929F0c199bDf812967207E4',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooComethUSDC-MUST',
    earnedTokenAddress: '0x8a198BCbF313A5565c64A7Ed61FaA413eB4E0931',
    earnContractAddress: '0x8a198BCbF313A5565c64A7Ed61FaA413eB4E0931',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cometh-usdc-must',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Cometh',
    assets: ['USDC', 'MUST'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://swap.cometh.io/#/add/0x2791bca1f2de4661ed88a30c99a7a9449aa84174/0x9c78ee466d6cb57a4d01fd887d2b5dfb2d46288f',
    buyTokenUrl:
      'https://swap.cometh.io/#/swap?inputCurrency=0x2791bca1f2de4661ed88a30c99a7a9449aa84174&outputCurrency=0x9c78ee466d6cb57a4d01fd887d2b5dfb2d46288f',
  },
];
